import React, { Component } from 'react';
import myb2b from '../../services/myb2b';
import jwt_decode from "jwt-decode";
import { Link } from 'react-router-dom';
class Monentreprise extends Component {
    constructor (props) {
        super(props)
        var dataUser=jwt_decode(localStorage.getItem('tokenb2b'));
        this.state = {
            vuesentreprise:0,
            vuesproduits:0,
            img : dataUser.img,
            title : dataUser.title,
            setinterval:false
        }

        myb2b.getNbVues().then((res)=>{
            if(res.data.get){
                
                this.setState({
                    vuesentreprise:res.data.data.vuesEntreprise,
                    vuesproduits:res.data.data.vuesProduits,
                    setinterval:true
                }) 
            }
        });
        if(this.state.setinterval){
            // setInterval(() => {
            //     myb2b.getNbVues().then((res)=>{
            //         if(res.data.get){
            //             this.setState({
            //                 vuesentreprise:res.data.data.vuesEntreprise,
            //                 vuesproduits:res.data.data.vuesProduits
            //             }) 
            //         }
            //     });
            // }, 10000);
        }
        
    }
    render() {
        return (
            <div className="widget stick-widget">
                <h4 className="widget-title">{localStorage.getItem("langb2b")==="en" ?"MY COMPANY :" : "MON ENTREPRISE :"}</h4>
                <div className="your-page">
                    <figure>
                    <Link to="/entreprise/agence-maritime-mohab/" title={this.state.title}><img onContextMenu={(e)=> e.preventDefault()} src={this.state.img} alt={this.state.title} /></Link>
                    </figure>
                    <div className="page-meta">
                    <Link to="/entreprise/agence-maritime-mohab/" title className="underline">{this.state.title}</Link>
                    </div>
                </div>
                <ul className="naves" style={{overflow: 'auto'}}>
                    <li>
                    <span><b>{localStorage.getItem("langb2b")==="en" ?"Views of my company :" : "Vues de mon entreprise :"}</b> {this.state.vuesentreprise} {localStorage.getItem("langb2b")==="en" ?"View" : "Vue"}{this.state.vuesentreprise>1?<>s</>:<></>}</span>
                    </li>
                    <li>
                    <span><b>{localStorage.getItem("langb2b")==="en" ?"Views of my products :" : "Vues de mes produits :"}</b> {this.state.vuesproduits} {localStorage.getItem("langb2b")==="en" ?"View" : "Vue"}{this.state.vuesproduits>1?<>s</>:<></>}</span>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Monentreprise;







