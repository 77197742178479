
import React, { Component } from 'react';
import DataHome from '../../services/home';
import './style.css';
import { withTranslation } from 'react-i18next';
class Stat extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            entreprises : 0,
            produits : 0,
            devis : 0,
            offresemploi : 0,
        }
        DataHome.getCompteursStat().then((res)=>{
            if(res.data.get){
                this.setState({
                    entreprises : res.data.entreprise,
                    produits : res.data.produit,
                    devis : res.data.devis,
                    offresemploi : res.data.offreemploi
                });
            }
        });
    }
  
    render() {
        const { t } = this.props;
        return ( 
            <div className="section-full text-white bg-img-fix content-inner overlay-black-middle" style={{backgroundImage:"url(/assets/img/compteurs-bg.webp)"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 ">
                            <div className="dex-box text-primary border-2 counter-box m-b30 text-compteurs">
                                <h2 className="text-uppercase m-a0 p-a15 "><i className="fa fa-th-large m-r20"></i> <span className="counter">{this.state.produits}</span></h2>
                                <h5 className="dlab-tilte  text-uppercase m-a0"><span className="dlab-tilte-inner skew-title bg-primary p-lr15 p-tb10">{t("Produits")}</span></h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="dex-box text-primary border-2 counter-box m-b30 text-compteurs">
                                <h2 className="text-uppercase m-a0 p-a15 "><i className="fa fa-building m-r20"></i> <span className="counter">{this.state.entreprises}</span></h2>
                                <h5 className="dlab-tilte  text-uppercase m-a0"><span className="dlab-tilte-inner skew-title bg-primary p-lr15 p-tb10">{t("Entreprises")}</span></h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="dex-box text-primary border-2 counter-box m-b30 text-compteurs">
                                <h2 className="text-uppercase m-a0 p-a15 "><i className="fa fa-file-alt m-r20"></i> <span className="counter">{this.state.devis}</span></h2>
                                <h5 className="dlab-tilte  text-uppercase m-a0"><span className="dlab-tilte-inner skew-title bg-primary p-lr15 p-tb10">{t("Demandes Devis")}</span></h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="dex-box text-primary border-2 counter-box m-b10 text-compteurs">
                                <h2 className="text-uppercase m-a0 p-a15 "><i className="fa fa-briefcase m-r20"></i> <span className="counter">{this.state.offresemploi}</span></h2>
                                <h5 className="dlab-tilte  text-uppercase m-a0"><span className="dlab-tilte-inner skew-title bg-primary p-lr15 p-tb10">{t("Offres d'emploi")}</span></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        );
    }
}
export default withTranslation()(Stat);

