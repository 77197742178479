import React, { Component } from 'react';
import { useEffect } from "react";
import { useState } from "react";
import Formations from '../../services/formations';
import Slider from '../Slider/pageFacebook';
import BtnPartage from '../Slider/btnPartage';
import BlockActualites from '../Slider/BlockActualites';
import Compteur from './compteur';
import Item from './item';
import MetaTags from 'react-meta-tags';
import { Redirect } from 'react-router';
import Auth from '../../services/Auth'
import {b2bNameSite,b2bDescriptionSite} from '../../config.json';
import './formations.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useParams } from 'react-router-dom';
import { Markup } from 'react-render-markup';
import i18next from 'i18next';
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';
import Modal, { closeStyle } from 'simple-react-modal';
function Detail(props){ 
    let { slug } = useParams();
    const [offset, setOffset] = useState(0);
    const [userLogin, setuserLogin] = useState(false);
    const [dataUser, setdataUser] = useState(null);
    const [redirect, setredirect] = useState(false);
    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        Auth.isLogin().then((res)=>{
            if(res.data!=false){
                setuserLogin(true);
                setdataUser(res.data);
            }else{
                setuserLogin(false);
                setdataUser(null);
                
            }
             setredirect(true);
        });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    if(redirect){
        if(userLogin){
            return ( 
                <Index slug={slug} t={props.t} offset={offset} userLogin={userLogin}/>
            );
        }else{
            return ( 
                <Redirect to={"/"+i18next.language+"/webinaires"}/>
            );
        }
    }else{
        return (<></>);
    }
}
const customStyles = {
    content: {
        
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
export default withTranslation()(Detail)
class Index extends Component
{
    constructor (props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        super(props)
        this.state = {
            slug : props.slug,
            data : null,
            items:[],
            participe: false
        }
        Formations.getDetail(this.state.slug).then((resdetail)=>{
            if(resdetail.data.get){
                this.setState({data : resdetail.data.data});
                Formations.getFormations(1,"").then((res)=>{
                    if(res.data.get){
                        var items = [];
                        res.data.data.forEach(actualite => {
                            if(resdetail.data.data.id!=actualite.id && items.length<3){
                                items.push(<Item 
                                    t={this.props.t}
                                    userLogin={this.props.userLogin}
                                    key={actualite.id} 
                                    data={actualite} 
                                    open={()=>{window.location.href="/"+i18next.language+"/webinaires/"+actualite.url}}
                                    link="href"
                                    />); 
                            }
                            
                        }); 
                        this.setState({progressPage : 100});
                        setTimeout(() => {
                            this.setState({showProgressPage:false}); 
                        }, 1000);
                        this.setState({items : []});
                        this.setState({items : items});
                    }
                });
            }
        });
    }
    openModal = () => {
        this.setState({ participe: true })
    }

    closeModal = () => {
        this.setState({ participe: false })
    }
    participe = (e) => {
        Formations.participe(this.state.data.title, this.state.data.content, this.state.data.date , this.state.data.time)
            .then((res) => {
                if (res.data.register) {
                    this.openModal();
                }
            });
    }
    render() {
        const {t} = this.props;
        if(this.state.data){
            
            var content = "";
            
            var post_title = "";
            var url = "";
            if (i18next.language == "fr" || i18next.language == "fr-FR") {
                content = this.state.data.post_content;
                
                post_title = this.state.data.post_title;
                url=this.state.data.urlPartage;
            } else if (i18next.language == "en") {
                content = this.state.data.en_post_content;
                
                post_title = this.state.data.en_post_title;
                url=this.state.data.urlPartage.replace("/fr/", "/en/");
            } else {
                content = this.state.data.post_content;
               
                post_title = this.state.data.post_title;
                url=this.state.data.urlPartage;
            }
            return (<>
                <MetaTags>
                    <title>{this.state.data?post_title:""} | {b2bNameSite} | {b2bDescriptionSite}</title>
                </MetaTags>
                <div className="page-content page-detai-webinaires">
                <Modal
                        isOpen={this.state.participe}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel={`Votre participation aux formation ${this.state.data.post_title} à été enregistrer avec succès`}
                    >
                        <h2>Participation : </h2>
                        <p>{`Votre participation aux formation ${this.state.data.post_title} à été enregistrer avec succès`}</p>
                        <button onClick={this.closeModal}>close</button><br />
                    </Modal>
                    <section id="list-places-wrapper" style={{marginTop: 0, background: '#fff'}}>
                        <div className="container">
                            <div className="col-md-12 px-1" style={{backgroundColor: '#fff', paddingTop: '15px'}}>
                                <div className="row">
                                    <div className="col-md-8 px-1" >
                                        <div className="col-md-12" style={{border: '1px solid #b8c7c8', paddingTop: '15px'}}> 
                                            <div className="col-md-12 bottom-header-bar" style={{position: 'relative', width: '100%'}}>
                                                <div className="row">
                                                    <div className="col-md-12 d-flex">
                                                        <h1 className="media-heading title-blog" style={{fontSize: '25px', fontWeight: 'bold', margin: 0, marginBottom: '10px',textAlign:"left",width:"calc(100% - 100px)"}}>{this.state.data.title}</h1>
                                                        {this.state.data.date_expirer?<></>:<>
                                                        <button type="button" onClick={this.participe} className='btn-b2b btn-participer' >{t("Je Participe")}</button>
                                                        </>}
                                                    </div>
                                                </div>
                                            </div>
                                            <figure style={{position: 'relative'}}>
                                                {/* <div style={{width: 'fit-content', backgroundColor: '#ff0000', height: '30px', padding: '5px', position: 'absolute', left: '5px', top: '5px'}}>
                                                    <span style={{float: 'left', marginRight: '20px', fontWeight: 600, color: '#fff', textTransform: 'uppercase', textAlign: 'right'}}>{this.state.data.date_deb_D} {this.state.data.date_deb_M},{this.state.data.date_deb_Y} à {this.state.data.time}</span>
                                                </div> */}
                                                <img onContextMenu={(e)=> e.preventDefault()} className="wpnaw-news-image" src={this.state.data.img} alt="" style={{width: '100%', height: '400px', objectFit: 'cover'}} />
                                                <Compteur data={this.state.data} />
                                            </figure>
                                            <hr />
                                            <div style={{fontSize: '15px', lineHeight: '24px', fontFamily: 'Verdana, Geneva, sans-serif', padding: '15px', paddingTop: '0px', textAlign: 'justify'}}>
                                                <Markup markup={content} />
                                                <p className="pb-0 mb-0 w-100 text-center">
                                                    <span style={{fontWeight: 'bold', color: '#5d5f5e'}}>{t("PARTAGER SUR")} : </span>
                                                </p>
                                                <div className='mt-4 w-100'>
                                                    <BtnPartage 
                                                    image={this.state.data.img} 
                                                    title={post_title} 
                                                    content={content}
                                                    url={url} />
                                                </div>
                                            </div>
                                        </div>
                                        <BlockActualites />
                                    </div>
                                    <div className="col-md-4 px-1" >
                                        <div className="col-md-12 px-2 pb-3 mb-0" style={{backgroundColor: '#fff', paddingTop: '10px', marginBottom: '15px', border: '1px solid #b8c7c8', borderBottomWidth: '0px'}}>
                                            <span className="title-lastest" style={{fontSize: '17px', textTransform: 'uppercase', color: '#aaa', marginBottom: '10px', borderBottom: 'solid 1px #ccc', paddingBottom: '10px'}}>{t('LES DERNIÈRES FORMATIONS AUX ENTREPRISES')}:</span>
                                            <div className='block-last-items'>
                                               {this.state.items}
                                            </div>
                                        </div>
                                        <div id="page_facebook">
                                            <div style={{border: '1px solid #b8c7c8'}} className="col-md-12 px-0">
                                                <Slider width={400}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
    
                </div>
            </>);
        }else{
            return (
                <div className="page-content">
                    <section id="list-places-wrapper" style={{marginTop: 0, background: '#fff'}}>
                        <div className="container">
                            <div className="col-md-12 px-1" style={{backgroundColor: '#fff', paddingTop: '15px'}}>
                                <div className="row">
                                    <div className="col-md-8 px-1" >
                                        <div className="col-md-12" style={{border: '1px solid #b8c7c8', paddingTop: '15px'}}> 
                                            <div className="col-md-12 bottom-header-bar" style={{position: 'relative', width: '100%'}}>
                                                <div className="row">
                                                    <div className="col-md-12 px-0">
                                                        <h1 className="media-heading title-blog loder-title-blog" style={{fontSize: '25px', fontWeight: 'bold', margin: 0, marginBottom: '10px'}}></h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <figure style={{position: 'relative'}}>
                                                
                                                <div className="wpnaw-news-image loder-image-blog " style={{width: '100%', height: '400px', objectFit: 'cover'}}> </div>
                                            </figure>
                                            <hr />
                                            <div style={{fontSize: '15px', lineHeight: '24px', fontFamily: 'Verdana, Geneva, sans-serif', padding: '15px', paddingTop: '0px', textAlign: 'justify'}}>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                            </div>
                                        </div>
                                        <div className="section-detail-wrapper pt-2">
                                            <div className="col-md-12" style={{backgroundColor: '#fff', paddingTop: '10px', marginBottom: '15px', border: '1px solid #b8c7c8'}}>
                                                <span className="title-lastest" style={{fontSize: '17px', textTransform: 'uppercase', color: '#aaa', marginBottom: '20px'}}> {t('Dans la même catégorie')}:</span>
                                                <div className="row"> 
                                                    
                                                </div>
                                            </div>          
                                        </div>
                                    </div>
                                    <div className="col-md-4 px-1" >
                                        <Slider />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        }
        
    }
}



