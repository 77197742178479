import React, { Component } from 'react';
import Auth from '../../services/Auth';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import './style.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import { GoogleKey, backUrl } from "../../config.json";
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';
class Login extends Component {
    constructor(props) {
        super(props)
        const { t } = this.props;
        const code = this.randomNum(8, 20);
        this.state = {
            username: "",
            t: t,
            password: "",
            captcha: "",
            captchaCode: code,
            captchaUrlImage: backUrl + "/b2b/api/captcha.php?code=" + (code.toString(2)),
            showErrorMessage: false,
            errorMessage: "",
            textLogin: null,
            redirect: <></>,
            showConfirmerPassword: "password"
        }
    }
    randomNum = (min, max) => {
        return Math.floor(Math.random() * (max - min)) + min;
    }
    changeUsername = (e) => {
        this.setState({ username: e.target.value });
        this.setState({
            username: e.target.value,
            errorMessage: "",
            showErrorMessage: false
        });
    }
    changePassword = (e) => {
        this.setState({
            password: e.target.value,
            errorMessage: "",
            showErrorMessage: false
        });
    }
    changeCaptcha = (e) => {
        this.setState({
            captcha: e.target.value,
            errorMessage: "",
            showErrorMessage: false
        });
    }
    submitLogin = () => {
        if (this.state.username == "") {
            this.setState({
                errorMessage: "Adresse email est nécessaire",
                showErrorMessage: true
            });
            return false;
        }
        if (this.state.password == "") {
            this.setState({
                errorMessage: "Mot de passe obligatoire",
                showErrorMessage: true
            });
            return false;
        }
        // if(this.state.captcha==""){
        //     this.setState({
        //         errorMessage:"Captcha est obligatoire",
        //         showErrorMessage:true
        //     });
        //     return false;
        // }
        // if(this.state.captcha!=this.state.captchaCode){
        //     this.setState({
        //         errorMessage:"Le code captcha est incorrect",
        //         showErrorMessage:true
        //     });
        //     return false;
        // }
        this.setState({ textLogin: <span className='loading-login'></span> });
        console.log(this.state.username)
        console.log(this.state.password)
        Auth.login(this.state.username, this.state.password).then((res) => {

            console.log(res.data)
            if (res.data.code == 3 && res.data.reponse == "unconfirm") {
                localStorage.setItem('tokenb2b', "");
                this.setState({ redirect: <Redirect to={"/" + i18next.language + "/veuillez-confirmer-votre-compte"} /> });
            } else if (res.data.code == 1) {
                localStorage.setItem('tokenb2b', res.data.token);
                window.location.reload(false);
            } else {
                if (res.data.reponse == "Compte inexsitant") {
                    this.setState({
                        errorMessage: "Adresse email est inexsitant",
                        showErrorMessage: true,
                        textLogin: null
                    });
                    return false;
                } else if (res.data.reponse == "incorrect password") {
                    this.setState({
                        errorMessage: "Mot de passe incorrect",
                        showErrorMessage: true,
                        textLogin: null
                    });
                    return false;
                } else {
                    this.setState({
                        errorMessage: "Erreur de Connexion",
                        showErrorMessage: true,
                        textLogin: null
                    });
                    return false;
                }
            }
        });
    }
    clickShowConfirmerPassword = (e) => {
        if (this.state.showConfirmerPassword == "password") {
            this.setState({ showConfirmerPassword: "text" });
        } else {
            this.setState({ showConfirmerPassword: "password" });
        }
    }
    render() {
        const { t } = this.props;
        var errorMessage = "";
        if (this.state.errorMessage != "")
            errorMessage = t(this.state.errorMessage);
        return (
            <>
                {this.state.redirect}
                <div className="home_search_b2b" id="home_search_b2b" style={{ "height": "auto", "right": "5%", "top": "40px", "position": "absolute", "zIndex": "99", "paddingTop": "30px", "backgroundImage": "linear-gradient(#f0f2f3bd, #f0f2f324)", "borderRadius": "5px", "border": "1px solid #f0f2f300", "width": "400px" }}>
                    <div className="col-md-12 col-sm-12 search-input">
                        <div className="content-ihc">
                            <div className="b2b-login-form-wrap b2b-login-template-10">
                                {this.state.showErrorMessage ? <div id="error">{this.state.errorMessage}</div> : <></>}
                                <form action="#" method="post" id="ihc_login_form">
                                    <div className="impu-form-line">
                                        <i className="fa fa-user icon-login-user"></i>
                                        <input type="email" value={this.state.username} id="iump_login_username" onChange={this.changeUsername} placeholder={t("Adresse email")} required="required" />
                                    </div>
                                    <div className="impu-form-line">
                                        <i className="fa fa-unlock-alt icon-login-user"></i>
                                        <input type={this.state.showConfirmerPassword} value={this.state.password} id="iump_login_password" onChange={this.changePassword} placeholder={t("Mot de passe")} required="required" />
                                        <div className="pass_eye" onClick={this.clickShowConfirmerPassword}><i className="fa fa-eye" id="fa-eye-home-b2b" aria-hidden="true" /></div>
                                    </div>
                                    <div className="impu-remember-wrapper">
                                        <input type="checkbox" value="forever" name="rememberme" className="impu-form-input-remember" />
                                        <label className="impu-form-label-remember">{t('Se souvenir de moi')}</label>
                                    </div>
                                    <div className="impu-form-links-pass">
                                        <Link to={"/" + i18next.language + "/rest-pass"} >{t('Mot de passe oublié ?')}</Link>
                                    </div>
                                    <div className="b2b-clear">
                                        {/* <img onContextMenu={(e)=> e.preventDefault()} src={this.state.captchaUrlImage} alt="captcha"/>
                                        <input type="text" value={this.state.captcha} onChange={this.changeCaptcha} autoComplete="off" id="result" style={{width:"60px",height:"40px"}}/> */}
                                    </div>
                                    <div className="impu-form-line impu-form-submit">
                                        <i className="icone-btn-connecter"></i>
                                        <button type="button" onClick={this.submitLogin} className="btn-connecter">{this.state.textLogin ? this.state.textLogin : t("Se connecter")}</button>
                                    </div>
                                    <div className="impu-form-links">
                                        <div className="impu-form-links-reg">
                                            {t("Vous n'avez pas de compte?")}
                                            <Link to={"/" + i18next.language + "/inscription"}>{t('Inscrivez-vous')}</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withTranslation()(React.memo(Login));

