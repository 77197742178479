
import './App.css';
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Home/index';
import Header from './header/header';
import Footer from './footer/footer';
import Entreprises from './Entreprises/index';

import EntreprisesDetail from './Entreprises/detail';
import AddEntreprise from './Entreprises/add';
import ProduitsDetail from './ProduitsServices/Detail/index';
import ProduitsServices from './ProduitsServices/index';
import Actualites from './Actualites/index';
import ActualiteDetail from './Actualites/detail';
import Experts from './Experts/index';
import ExpertDetail from './Experts/detail';
import Evenements from './Evenements/index';
import EvenementDetail from './Evenements/detail';
import OffresDemplois from './OffresDemploi/index';
import OffresDemploiDetail from './OffresDemploi/detail';
import OffresMissions from './OffresMissions/index';
import OffresMissionDetail from './OffresMissions/detail';
import Contact from './Pages/contact';
import Recherche from './Pages/recherche';
import AbonnementB2B from './Pages/abonnementB2B';
import Deconnexion from './Pages/deconnexion';
import Page404 from './page404';
import Inscription from './Comptes/inscription';
import Signin from './Comptes/signin';
import CompteExpert from './Comptes/compteExpert';
import CompteEntreprise from './Comptes/compteEntreprise';
import RestPass from './Comptes/restPass';
import NewPass from './Comptes/newPass';
import ConfirmerCompte from './Comptes/confirmerCompte';
import ConfirmerCompteValide from './Comptes/confirmerCompteValide';
import Inscription1 from './Comptes/expert/inscription1';
import Inscription2 from './Comptes/expert/inscription2';
import Inscription3 from './Comptes/expert/inscription3';
import InscriptionMail from './Comptes/expert/inscriptionMeil';
import AjouterEntrepriseAvecSucces from './Entreprises/ajouterEntrepriseAvecSucces';
import DevisExpress from './footer/devisExpress';
import PageDevisExpress from './Pages/devisexpress';
import SousTritance from './sousTritance/index';
import SousTritanceDetail from './sousTritance/detail';
import Conference from './conference/index';
import ConferenceDetail from './conference/detail';
import VentesAuxEncheres from './ventesAuxEncheres/index';
import VentesAuxEncheresDetail from './ventesAuxEncheres/detail';
import Formations from './formations/index';
import FormationsDetail from './formations/detail';

import ReactGA from 'react-ga'
import Myb2b from './MyB2B/index';

class App extends Component
{
    constructor (props) {
        super(props)
        this.state = {
        }
        ReactGA.initialize("UA-125058676-1")
    }
    render() {
        const { t } = this.props;
        return ( 
            <BrowserRouter>  
                <div class="page-wraper">
                    <Header />
                    <Switch>
                        <Route path="/" exact ><Home /></Route>
                        <Route path="/fr/" exact ><Home /></Route>
                        <Route path="/en/" exact ><Home /></Route>
                        <Route path="/:langb2b/entreprises" exact ><Entreprises /></Route>
                        <Route path="/:langb2b/region/:region" exact ><Entreprises /></Route>
                        <Route path="/:langb2b/entreprise/:slug" exact ><EntreprisesDetail /></Route>
                        <Route path="/:langb2b/produits-et-services" exact ><ProduitsServices /></Route>
                        <Route path="/:langb2b/produit/:slug" exact ><ProduitsDetail /></Route>
                        <Route path="/:langb2b/evenements" exact ><Evenements /></Route>
                        <Route path="/:langb2b/evenement/:slug" exact ><EvenementDetail /></Route>
                        <Route path="/:langb2b/les-offres-demploi" exact ><OffresDemplois /></Route>
                        <Route path="/:langb2b/offre-emploi/:slug" exact ><OffresDemploiDetail /></Route>
                        <Route path="/:langb2b/les-offres-missions" exact ><OffresMissions /></Route>
                        <Route path="/:langb2b/offre-de-mission/:slug" exact ><OffresMissionDetail /></Route>
                        <Route path="/:langb2b/actualites" exact ><Actualites /></Route>
                        <Route path="/:langb2b/actualites/:slug" exact ><ActualiteDetail /></Route>
                        <Route path="/:langb2b/myb2b" exact ><Myb2b /></Route>
                        <Route path="/:langb2b/contactez-nous" exact ><Contact /></Route>
                        <Route path="/:langb2b/recherche" exact ><Recherche /></Route>
                        <Route path="/:langb2b/inscription" exact ><Inscription /></Route>
                        <Route path="/:langb2b/signin" exact ><Signin /></Route>
                        <Route path="/:langb2b/rest-pass" exact ><RestPass /></Route>
                        <Route path="/:langb2b/new-pass" exact ><NewPass /></Route>
                        <Route path="/:langb2b/compte-expert" exact ><CompteExpert /></Route>
                        <Route path="/:langb2b/compte-entreprise" exact ><CompteEntreprise /></Route>
                        <Route path="/:langb2b/deconnexion" ><Deconnexion /></Route>
                        <Route path="/:langb2b/add" ><AddEntreprise /></Route>
                        <Route path="/:langb2b/veuillez-confirmer-votre-compte" ><ConfirmerCompte /></Route>
                        <Route path="/:langb2b/confirmer-votre-compte-avec-succes" ><ConfirmerCompteValide /></Route>
                        <Route path="/:langb2b/ajouter-entreprise-avec-succes" ><AjouterEntrepriseAvecSucces /></Route>
                        <Route path="/:langb2b/expert/inscription" ><Inscription1 /></Route>
                        <Route path="/:langb2b/expert/inscription-deuxieme" ><Inscription2 /></Route>
                        <Route path="/:langb2b/expert/inscription-fin" ><Inscription3 /></Route>
                        <Route path="/:langb2b/expert/inscription-mail" ><InscriptionMail /></Route>
                        <Route path="/:langb2b/experts" exact ><Experts /></Route>
                        <Route path="/:langb2b/expert/:slug" exact ><ExpertDetail /></Route>
                        <Route path="/:langb2b/sous-traitance/" exact ><SousTritance /></Route>
                        <Route path="/:langb2b/sous-traitance/demande/:slug" exact ><SousTritanceDetail type="demande"/></Route>
                        <Route path="/:langb2b/sous-traitance/offre/:slug" exact ><SousTritanceDetail type="offre"/></Route>
                        <Route path="/:langb2b/ventes-aux-encheres/" exact ><VentesAuxEncheres /></Route>
                        <Route path="/:langb2b/ventes-aux-enchere/:slug" exact ><VentesAuxEncheresDetail /></Route>
                        <Route path="/:langb2b/webinaires/" exact ><Conference /></Route>
                        <Route path="/:langb2b/webinaires/:slug" exact ><ConferenceDetail /></Route>
                        <Route path="/:langb2b/formations/" exact ><Formations /></Route>
                        <Route path="/:langb2b/formations/:slug" exact ><FormationsDetail /></Route>
                        <Route path="/:langb2b/devis-express" exact ><PageDevisExpress /></Route>
                        
                        <Route path="/:langb2b/abonnement-b2b" exact ><AbonnementB2B /></Route>
                        <Route path="/:langb2b/404" exact ><Page404 /></Route>
                        <Route path="/:key" ><Page404 /></Route>
                        <Route path="/:langb2b/:key" ><Page404 /></Route>
                    </Switch>
                    <DevisExpress />
                    <Footer />
                </div>
            </BrowserRouter>
        );
    }
}
export default App;



