import './style.css';
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { b2bNameSite, b2bDescriptionSite } from '../../config.json';
import { Link } from 'react-router-dom';
import Auth from '../../services/Auth';
import { Redirect } from 'react-router';
import i18next from 'i18next';
import ReactGA from 'react-ga'
export default class Index extends Component {

    constructor(props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        const urlParams = new URLSearchParams(window.location.search);
        const rest_password = urlParams.get('rest_password');
        const user = urlParams.get('user');

        super(props)
        this.state = {
            rest_password: rest_password,
            user: user,
            pass: "",
            confpass: "",
            errorpassword: false,
            errorconfpassword: false,
            disabled: false,
            errorsubmitForm: 0,
            isLogin: false
        }
        Auth.isLogin().then((res) => {
            if (res.data != false) {
                this.setState({ isLogin: true });
            } else {
                this.setState({ isLogin: false });
            }
        });
    }
    changeConfPassword = (e) => {
        this.setState({
            confpass: e.target.value
        })
        this.setState({ errorconfpassword: false });
        if (e.target.value != "" && e.target.value != this.state.pass) {
            this.setState({ errorconfpassword: true });
        }
    }
    changePassword = (e) => {
        this.setState({
            pass: e.target.value
        })
    }
    submitForm = () => {
        if (this.state.pass == "" || this.state.confpass == "") {
            this.setState({
                errorconfpassword: true,
                errorpassword: true
            })
            return false;
        } else {
            this.setState({
                disabled: true
            })
            Auth.UpdatePass(this.state.pass, this.state.rest_password, this.state.user).then((res) => {
                if (res.data.update) {
                    this.setState({ errorsubmitForm: 1 })
                } else {
                    this.setState({ errorsubmitForm: 2 })
                }
                this.setState({
                    disabled: false
                })
            });
        }
    }
    render() {
        if (this.state.isLogin) {
            var lang = "fr";
            if (i18next.language == "en") lang = "en";
            return (<Redirect to={"/" + lang} />);
        } else {
            return (
                <>
                    {(this.state.rest_password == "" || this.state.user == "") ? <Redirect to="/404" /> : <></>}
                    <MetaTags>
                        <title>{localStorage.getItem("langb2b") === "fr" ? "Nouvelle mot de passe" : "New Password"} | {b2bNameSite} | {localStorage.getItem("langb2b") === "fr" ? b2bDescriptionSite : "B2B marketplace and B2B networking platform in France and abroad"}</title>
                    </MetaTags>
                    <div className="page-content">
                        <section style={{ backgroundColor: '#fff', minHeight: '450px', marginBottom: '-50px' }}>
                            <h1 className="title__bordered">Entrez votre nouveau votre mot de passe </h1>
                            <div className="container">
                                <div className='row'>
                                    <div className='col-md-3'></div>
                                    <div className="col-md-6" style={{ paddingTop: '20px' }}>
                                        {this.state.errorsubmitForm == 0 ?
                                            <div style={{ margin: '0 auto' }}>
                                                <div style={{ background: '#fff' }}>
                                                    <input type="password" name="email" className="restPassInputt" placeholder="Entrez votre nouveau mot de passe " onChange={this.changePassword} />
                                                    {this.state.errorpassword ? <label className="error" style={{ display: "block" }}>Mot de passe Est Obligatoire</label> : <></>}
                                                    <input type="password" name="email" className="restPassInputt" placeholder="Confirmez votre nouveau mot de passe " onChange={this.changeConfPassword} />
                                                    {this.state.errorconfpassword ? <label className="error" style={{ display: "block" }}>Les deux mots de passe ne sont pas identiques</label> : <></>}
                                                </div>
                                                <div style={{ background: '#fff', paddingTop: '30px' }}>
                                                    <button onClick={this.submitForm} disabled={this.state.disabled ? "disabled" : ""} style={{ textTransform: "none", fontSize: "18px" }} className="button button-primary button-large btn-b2b" >Réinitialiser mon mot de passe</button>
                                                </div>
                                            </div>
                                            : this.state.errorsubmitForm == 1 ? <>
                                                <div style={{ textAlign: 'center', paddingTop: '7em' }}>
                                                    <h2>Votre mot de passe a été modifié avec succès</h2>
                                                    <i className="fa fa-check" style={{ fontSize: "90px", color: "green" }} />
                                                </div>
                                            </> : <Redirect to="/404" />}
                                    </div>
                                    <div className='col-md-3'></div>
                                </div>
                            </div>
                        </section>
                    </div>
                </>
            );
        }
    }
}


