import React,{ useEffect,useState } from "react";
import { useForm } from "react-hook-form";
import { prefixe }from "../../../config.json"
import Devis from "../../../services/devis"
import { withTranslation } from 'react-i18next';
const FormDevi=(props)=>{
    const [parent, setParent] = useState(props.parent);
    const [prodId, setProdId] = useState(props.IdProd);
    const [title, setTitle] = useState(props.title);
    const [minimale, setMinimale] = useState(props.minimale);
    const [optiondevipays, setOptiondevipays] = useState(null);
    const [showsociete, setShowsociete] = useState("d-none");
    const [loderFrome, setLoderFrome] = useState("d-none");
    const [sundDevis, setSundDevis] = useState(false);
    const [isSociete, setIsSociete] = useState(false);
    const [showactivite, setShowactivite] = useState("");
    const _time = new Date();
    const date = ('0' + _time.getDate()).slice(-2);
    const month = ('0' + (_time.getMonth() + 1)).slice(-2);
    const year = _time.getFullYear();
    const [time, setTime] = useState(`${year}-${month}-${date}`);
    const { t } = props;
    const { register, handleSubmit, reset , formState: { errors } } = useForm();
    const onSubmit = (data) => 
    {
        setLoderFrome("loder-from");
        console.log(data);
        if(isSociete){
            Devis.registerDevie(data,prodId,parent).then((res)=>{
                if(res.data){
                reset(); 
                setSundDevis(true);
                }
                setLoderFrome("d-none");
            }); 
        }else{
           Devis.registerDevieOffline(data,prodId,parent).then((res)=>{
                if(res.data){
                reset(); 
                setSundDevis(true);
                }
                setLoderFrome("d-none");
            }); 
        }
        
    }
    useEffect(() => {
        if(props.dataUser && props.dataUser.type=="societe") setIsSociete(true);
        Devis.getCountry().then((res)=>{
            if(res.data.get){
                var countrys = [];
                res.data.data.forEach(country => {
                    if(parseInt(country.code) == parseInt(prefixe)){
                        countrys.push(<option selected value={country.code}>{country.name}</option>);
                    }else{
                        countrys.push(<option value={country.code}>{country.name}</option>);
                    }
                    
                });
                setOptiondevipays(countrys);
            }
        });
    }, []);
    const chingeDeviEtes =(e)=>{
        if(e.target.value==1){
            setShowsociete("d-none");
            setShowactivite("");
        }else{
            setShowsociete("");
            setShowactivite("d-none");
        }
    }
    return (
        <div className="connxtion_body" style={{position:"relative"}}>
            <div className={loderFrome}>
                <span className='loading-login'></span>
            </div>
            <div className="content-ihc">
                {sundDevis?
                <div className="sund-form-sucsses">
                    <span>{t("Votre demande de devis a été enregistrée avec succès")}</span>
                </div>:<>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        {isSociete?<>
                        <div className="col-md-12 form-group">
                            <span className="form-control" style={{paddingTop: '8px'}}>{title}</span>
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_nom">{t("Nom")}* :</label>
                            <input {...register("nom", { required: true })} className="form-control" />
                            {errors.nom?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_prenom">{t("Poste")}* :</label>
                            <input {...register("poste", { required: true })} className="form-control" />
                            {errors.poste?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                        </div>
                        </>:<>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_etes">{t("Vous êtes")}* :</label>
                            <select {...register("devi_etes")} className="form-control" onChange={chingeDeviEtes}>
                                <option value="1">{t("Un particulier")}</option>
                                <option value="2">{t("Une entreprise")}</option>
                            </select>
                            {errors.devi_etes?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <div className={showactivite}>
                                <label htmlFor="devi_activite">{t("Activité")}* :</label>
                                <input {...register("activite", { required: showactivite==""?true:false })} className="form-control" />
                                {errors.activite?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                            </div>
                            <div className={showsociete}>
                                <label htmlFor="devi_nom_societe">{t("Nom de la société")}* :</label>
                                <input {...register("nom_societe", { required: showsociete==""?true:false })} className="form-control" />
                                {errors.nom_societe?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                            </div>
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_nom">{t("Nom")}* :</label>
                            <input {...register("nom", { required: true })} className="form-control" />
                            {errors.nom?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_prenom">{t("Prénom")}* :</label>
                            <input {...register("prenom", { required: true })} className="form-control" />
                            {errors.prenom?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_email">{t("Email")}* :</label>
                            <input type="email" {...register("email", { required: true })} className="form-control" />
                            {errors.email?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_telephone">{t("Téléphone")}* :</label>
                            <input type="tel" {...register("telephone", { required: true , maxLength: 10 })} className="form-control" />
                            {errors.telephone?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_pays">{t("Pays")}* :</label>
                            <select {...register("devi_pays")} className="form-control">
                                {optiondevipays}
                            </select>
                            {errors.devi_pays?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_ville">{t("Ville")}* :</label>
                            <input {...register("ville", { required: true })}  className="form-control" />
                            {errors.ville?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_adresse">{t("Adresse")}* :</label>
                            <input {...register("adresse", { required: true })} className="form-control" />
                            {errors.adresse?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_code_postal">{t("Code postal")}* :</label>
                            <input {...register("code_postal", { required: true })} className="form-control" />
                            {errors.code_postal?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                        </div>
                        </>}
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_date_livraison">{t("Date de livraison souhaitée")}* :</label>
                            <input type="date" {...register("date_livraison", { required: true})} min={time} className="form-control" />
                            {errors.date_livraison?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_quantite">{t("Quantité")} * :</label>
                            <input type="number" {...register("quantite", { required: true ,min:minimale })} className="form-control" />
                            {errors.quantite?<span className="error d-block">{t("Ce champ est obligatoire et Quantité minimale")} {minimale} </span>:<></>}
                        </div>
                        <div className="col-md-12 form-group">
                            <label htmlFor="devi_pays">{t("Message")}* :</label>
                            <textarea className="form-control" {...register("message", { required: true })}/>
                            {errors.message?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                        </div>
                    </div>
                    
                    {/* {errors.exampleRequired && <span>This field is required</span>} */}
                    <input type="submit" value={t("Envoyer")} className="btn btn-b2b"/>
                </form>
                </>}
            </div>
        </div>
    );
}

export default withTranslation()(FormDevi);