import './style.css';
import React, { Component, useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import Login from '../header/loginheader';
import Auth from '../../services/Auth';
import { Redirect } from 'react-router';
import i18next from 'i18next';
import { b2bNameSite, b2bDescriptionSite } from '../../config.json';
import ReactGA from 'react-ga'
import { useParams, Link } from "react-router-dom";
const Index = () => {
    const [isLogin, setIsLogin] = useState(false);
    const [redirectLang, setRedirectLang] = useState(<></>);
    let { langb2b } = useParams();
    useEffect(() => {
        Auth.isLogin().then((res) => {
            if (res.data != false) {
                setIsLogin(true);
            } else {
                setIsLogin(false);
            }
        });
        if (langb2b == "en") {
            i18next.changeLanguage("en");
        } else {
            i18next.changeLanguage("fr");
        }
        if (langb2b != "fr" && langb2b != "en") {
            i18next.changeLanguage("fr");
            const url = window.location.href.replace('/fr-FR', '/fr').replace(window.location.origin, '').replace("/undefined", "/fr");
            setRedirectLang(<Redirect to={url} />);
        }
    }, [langb2b]);


    if (isLogin) {
        return (<Redirect to={"/" + i18next.language} />);
    } else {
        return (<>{redirectLang}<Signin /> </>);
    }
}
export default Index

class Signin extends Component {

    constructor(props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>{localStorage.getItem("langb2b") === "fr" ? "Se connecter" : "LogIn"} | {b2bNameSite} | {localStorage.getItem("langb2b") === "fr" ? b2bDescriptionSite : "B2B marketplace and B2B networking platform in France and abroad"}</title>
                </MetaTags>
                <div className="page-content page-connecter">
                    <section style={{ backgroundColor: '#fff', minHeight: '450px' }}>
                        <h1 className="title__bordered">SE CONNECTER À « {b2bNameSite} »</h1>
                        <div className="container">
                            <div className="col-md-12 pb-5" style={{ backgroundColor: '#fff', marginBottom: '65px' }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="div-loginn">
                                            <div className="connxtion_header">
                                                <h2>VOUS ÊTES DÉJÀ INSCRIT ?
                                                    <br />CONNECTEZ-VOUS:</h2>
                                            </div>
                                            <Login modal={false} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="div-loginn">
                                            <div className="connxtion_header">
                                                <h2>VOUS ÊTES NOUVEAU SUR <br />« {b2bNameSite}  » ?</h2>
                                            </div>
                                            <div style={{ padding: '10px' }}>
                                                <p className="test-b2b mb-4">
                                                    Inscrivez-Vous GRATUITEMENT sur sur notre réseau international de plateforme B2B, et bénéficiez de plein de fonctionnalités et d'avantages.<br /> C'est <strong>SIMPLE</strong>, <strong>RAPIDE</strong> et <strong>GRATUIT</strong> . <br />
                                                    Montrez le savoir-faire de votre entreprise sur votre vitrine dédiée : décrivez votre activité, ajoutez vos produits et vos services, publiez vos vidéos entreprise et produits, vos documents pdf, etc.
                                                </p>
                                                <div style={{ position: 'relative', width: '90%', marginLeft: '5%' }}>
                                                    <Link style={{ borderRadius: '50px !important', margin: "0px" }} to={"/" + i18next.language + "/inscription"} className="btn btn-connecter btn-b2b">
                                                        <i className="icone-btn-connecter" />S'inscrire</Link>
                                                </div>
                                                <p>
                                                    Suivez le processus d'inscription: ajoutez votre logo/photo de profil, votre présentation, vos mots-clés, vos services/produits, etc..
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}


