import './style.css';
import React, { Component , useRef } from 'react';
import MetaTags from 'react-meta-tags';
import { prefixe,lengthTel } from  "../../../config.json";
import Auth from '../../../services/Auth';
import Experts from '../../../services/experts';
import Enteprises,{getRegions} from '../../../services/enteprises';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import i18next from 'i18next';
import Header from './header';
import jwt_decode from "jwt-decode";
import { useForm } from "react-hook-form";
import Devis from "../../../services/devis"
import { useEffect } from "react";
import { useState } from "react";

export default class Index extends Component
{
    constructor (props) {
        window.scrollTo(0, 0);
        super(props)
        var userLogin=false;
        var dataUser=null;
        if(localStorage.getItem('tokenb2b') && localStorage.getItem('tokenb2b')!="undefined" && localStorage.getItem('tokenb2b')!=""){
           userLogin=true;
           dataUser=jwt_decode(localStorage.getItem('tokenb2b'));
        }
        this.state = {
            isLogin:userLogin,
            dataUser:dataUser
        }
        Auth.isLogin().then((res)=>{
            if(res.data!=false){
                this.setState({
                    isLogin:true,
                    dataUser:res.data
                });
            }else{
                this.setState({
                    isLogin:false,
                    dataUser:null
                });
            }
        });
        
    }
    render() {
        if (this.state.isLogin) {
            // setTimeout(() => {
            //    window.location.href="/b2b/admin/expert/inscription?token="+localStorage.getItem("tokenb2b"); 
            // }, 2000);
            // return ( <></> );
            return ( <Inscription dataUser={this.state.dataUser}/> );
        } else {
            return ( <Redirect to={"/"+i18next.language}/> );
        }
    }
}
function useScrollToError(errors) {
    useEffect(() => {
      const errorsvalues = Object.values(errors)
      if (errorsvalues.length > 0) {
        errorsvalues[0].ref.scrollIntoView(false)
      }
    }, [errors])
}
function Inscription(props) {
    const [dataUser, setDataUser] = useState(props.dataUser);
    const [dataInscription, setDataInscription] = useState(null);
    const [optiondevipays, setOptiondevipays] = useState(null);
    const [optiondevigouvernorat, setOptiondevigouvernorat] = useState(null);
    const [loderFrome, setLoderFrome] = useState("d-none");
    const [showgouvernorat, setShowgouvernorat] = useState("d-block");
    const [maxLengthGSM, setMaxLengthGSM] = useState(lengthTel);
    const [redirect, setRedirect] = useState(<></>);
    const [paysCode, setPaysCode] = useState(parseInt(prefixe));
    const { register, handleSubmit, reset ,setValue, formState: { errors } } = useForm();
    const formEl = useRef(null);
    const annee =[];
    useScrollToError(errors);
    
    var currentYear= new Date().getFullYear();
    for (let index = (parseInt(currentYear)-18); index >= 1900; index--) {
        annee.push(<option value={index}>{index}</option>); 
    }
    const onSubmit = (data) => 
    {
        setLoderFrome("loder-from");
        console.log(data);
        Auth.inscriptionExpert1(data,paysCode).then((res)=>{
            if(res.data.register){
                setLoderFrome("");
                setRedirect(<Redirect to={"/"+i18next.language+"/expert/inscription-deuxieme"}/>);
            }
        });
    }
    useEffect(() => {
        Devis.getCountry().then((res)=>{
            if(res.data.get){
                var countrys = [];
                res.data.data.forEach(country => {
                    if(country.code==prefixe){
                        countrys.push(<option value={country.name} data-PaysCode={country.code} selected>{country.name}</option>);
                        setValue('ville', country.name);
                    }else{
                        countrys.push(<option value={country.name} data-PaysCode={country.code}>{country.name}</option>);
                    }
                    
                });
                setOptiondevipays(countrys);
            }
        });
        Enteprises.getRegions().then((res)=>{
            if(res.data.get){
                var gouvernorats = [];
                res.data.childrens.forEach(gouvernorat => {
                    gouvernorats.push(<option value={parseInt(gouvernorat.id)}>{gouvernorat.name}</option>);
                });
                setOptiondevigouvernorat(gouvernorats);
            }
        });
        if(dataUser){
            Experts.getDetailByID(dataUser.code_user).then((res)=>{
                if(res.data.get){
                    setDataInscription(res.data.data);
                    setPaysCode(parseInt(res.data.data.codegsm))
                    setValue("last_name", res.data.data.last_name);
                    setValue("first_name", res.data.data.first_name);
                    setValue("civilite", res.data.data.civilite);
                    setValue("etat_civil", res.data.data.etat_civil);
                    setValue("ville", res.data.data.ville);
                    
                    setValue("gouvernorat", parseInt(res.data.data.gouvernorat));
                    setValue("phone", res.data.data.phone);
                    setValue("date_naissance_a", res.data.data.date_naissance.y);
                    setValue("date_naissance_m", res.data.data.date_naissance.m);
                    setValue("date_naissance_j", res.data.data.date_naissance.d);
                    setValue("addresse", res.data.data.addr1);
                    setValue("conduite", res.data.data.conduite);
                    setValue("linkedin", res.data.data.linkedin);
                    setValue("facebook", res.data.data.facebook);
                    setValue("twitter", res.data.data.twitter);
                }
            })
        }
        
        
    },[]);
   
  
    const chingePays =(e)=>{
        var paysCode = e.target.querySelector(':checked').getAttribute('data-PaysCode');
        if(("+"+paysCode)==prefixe){
            setShowgouvernorat("d-block");
            setMaxLengthGSM(lengthTel);
        }else{
            setShowgouvernorat("d-none");
            setMaxLengthGSM(20);
        }
        setPaysCode(paysCode);
    }
    const changeTelephon = (e)=>{
        let value = e.target.value;
        value = value.replace(/o/g, "");
        value = value.replace(/[^\d]/g, "");
        setValue('phone', value);
    }
    // useScrollToError(formEl);
    return (
        <div className="container dashboard-content mt-3 block-expert-inscription inscription1">
            {redirect}
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="section-block" id="basicform">
                    </div>
                    <Header active="1"/>
                    <div className="card ">
                        <div className="card-body">
                            <div className={loderFrome}>
                                <span className='loading-login'></span>
                            </div>
                            <form ref={formEl} onSubmit={handleSubmit(onSubmit)} className="f1 row m-form-b2b">
                                <div className="profile-edit-container col-md-12" id="block1">
                                    <div className="custom-form row">
                                        <div className="col-md-4"> 
                                            <label> NOM <span className="star">*</span>:</label>
                                            <input className="form-control" {...register("last_name", { required: true })}  placeholder="Veuillez saisir votre Nom" />
                                            {errors.last_name?<span className="error d-block text-transform-unset">Le champ « Nom » est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-4">
                                            <label> PRENOM <span className="star">*</span>:</label>
                                            <input className="form-control" {...register("first_name", { required: true })} placeholder="Veuillez saisir votre Prénom"/>
                                            {errors.first_name?<span className="error d-block text-transform-unset">Le champ « Prénom »  est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-4">
                                            <label> Civilité    <span className="star">*</span>:</label>
                                            <select style={{display: 'block !important'}} className="form-control bs-select-hidden" {...register("civilite", { required: true })}>
                                                <option value="">Sélectionnez Civilité</option>
                                                <option value="m">M</option>
                                                <option value="mme">Mme</option>
                                                <option value="mlle">Mlle</option>
                                            </select>
                                            {errors.civilite?<span className="error d-block text-transform-unset">Le champ « Civilité » est obligatoire </span>:<></>}
                                        </div>
                                    </div>
                                    <div className="custom-form row">
                                        <div className="col-md-6">
                                            <label> Etat civil<span className="star">*</span>:</label>
                                            <select style={{display: 'block !important'}} className="form-control bs-select-hidden" {...register("etat_civil", { required: true })}>
                                                <option value="">Sélectionnez etat civil</option>
                                                <option value="m">Marié(e)</option>
                                                <option value="s">Célibataire</option>
                                            </select>
                                            {errors.etat_civil?<span className="error d-block text-transform-unset">Le champ « Etat civil » est obligatoire </span>:<></>}
                                        </div>
                                        <div className="col-md-6">
                                            <label> Date de naissance <span className="star">*</span>:</label>
                                            <div className="date_naissance w-100">
                                                <div className='date_naissance_j w-100'>
                                                    <select className="form-control bs-select-hidden" {...register("date_naissance_j", { required: true })}>
                                                        <option value="">Jours</option>
                                                        {[...Array(32)].map((_, index) => index>0?<option value={index<10?"0"+index:""+index}>{index}</option>:<></>)}
                                                    </select>
                                                    {errors.date_naissance_j?<span className="error d-block text-transform-unset">Le champ « Jours » est obligatoire </span>:<></>}
                                                </div>
                                                <div className='date_naissance_m w-100' style={{margin:"0px 2px"}}>
                                                    <select  className="form-control bs-select-hidden" {...register("date_naissance_m", { required: true })}>
                                                        <option value="">Mois</option>
                                                        <option value="01">Janvier</option>
                                                        <option value="02">Février</option>
                                                        <option value="03">Mars</option>
                                                        <option value="04">Avril</option>
                                                        <option value="05">Mai</option>
                                                        <option value="06">Juin</option>
                                                        <option value="07">Juillet</option>
                                                        <option value="08">Août</option>
                                                        <option value="09">Septembre</option>
                                                        <option value="10">Octobre</option>
                                                        <option value="11">Novembre</option>
                                                        <option value="12">Décembre</option>                                  
                                                    </select>
                                                    {errors.date_naissance_m?<span className="error d-block text-transform-unset">Le champ « Mois » est obligatoire </span>:<></>}
                                                </div>
                                                <div className='date_naissance_a w-100'>
                                                    <select className="form-control  bs-select-hidden" {...register("date_naissance_a", { required: true })}>
                                                        <option value="">Anneé</option>
                                                        {annee}
                                                    </select>
                                                    {errors.date_naissance_a?<span className="error d-block text-transform-unset">Le champ « Année » est obligatoire </span>:<></>}
                                                </div>
                                            </div>
                                            
                                            
                                            
                                        </div>
                                    </div>
                                    <div className="custom-form row">
                                        <div className="col-md-6">
                                            <label> Pays   <span className="star">*</span>:</label>
                                            <select style={{display: 'block !important'}}  className="form-control bs-select-hidden" {...register("ville", { required: true ,onChange:(e) => {chingePays(e)}})}>
                                                <option value="">Choisissez</option>
                                                {optiondevipays}
                                            </select>
                                            {errors.ville?<span className="error d-block text-transform-unset">Le champ « Pays » est obligatoire</span>:<></>}   
                                        </div>
                                        <div className={"col-md-6 "+showgouvernorat} >
                                            <label> Gouvernorat   <span className="star">*</span>:</label>
                                            <select style={{display: 'block !important'}} className="form-control bs-select-hidden" {...register("gouvernorat", { required: true })}>
                                                <option value="" hidden disabled selected>Choisissez</option>
                                                {optiondevigouvernorat}
                                            </select>
                                            {errors.gouvernorat?<span className="error d-block text-transform-unset">Le champ « Gouvernorat » est obligatoire</span>:<></>}
                                        </div>
                                    </div>
                                    <div className="custom-form row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Photo de profil <span className="star">*</span>:</label>    
                                                <input type="file" {...register("file", { required: (dataInscription && dataInscription.image!="")?false:true })}  className="form-control " style={{lineHeight: '1.1', boxShadow: 'none', paddingTop: '10px'}} />
                                                {errors.file?<span className="error d-block text-transform-unset">Le champ « Photo de profil » est obligatoire</span>:<></>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label> GSM <span id="codegsm">+{paysCode}</span> <span className="star">*</span>:</label>
                                            <input className="form-control" placeholder="Veuillez saisir votre GSM" maxLength={maxLengthGSM} {...register("phone", { required: true , onChange:(e) => {changeTelephon(e) } })} />
                                            {errors.phone?<span className="error d-block text-transform-unset">Le champ « GSM » est obligatoire </span>:<></>}
                                            
                                        </div>
                                    </div>
                                    <div className="custom-form row">
                                        <div className="col-md-8">
                                            <label> Adresse exacte <span className="star">*</span>:</label>
                                            <input className="form-control" placeholder="Veuillez saisir votre Adresse exacte " {...register("addresse", { required: true })} />
                                            {errors.addresse?<span className="error d-block text-transform-unset">Le champ « Adresse» est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-4 flex-center">
                                            <div>
                                                <label> Permis de conduite <span className="star">*</span>:</label>
                                                <span className="user-conduite">
                                                    <input type="radio" id="conduite__987_o" value="o" {...register("conduite", { required: true})} /><label htmlFor="conduite__987_o" className="d-inline">oui</label>
                                                    <input type="radio" id="conduite__987_n" value="n" {...register("conduite", { required: true})} /> <label htmlFor="conduite__987_n" className="d-inline">non</label>
                                                </span>
                                                {errors.conduite?<span className="error d-block text-transform-unset">Le champ « Permis de conduite » est obligatoire</span>:<></>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-form row">
                                        <div className="col-md-12">
                                            <label> LINKEDIN :</label>
                                            <input  {...register("linkedin")} className="form-control" placeholder="Veuillez saisir le lien de votre profil sur LinkedIn " />
                                        </div>
                                    </div>
                                    <div className="custom-form row">
                                        <div className="col-md-12">
                                            <label>  FACEBOOK :</label>
                                            <input  {...register("facebook")} className="form-control" placeholder="Veuillez saisir le lien de votre profil sur Facebook" />
                                        </div>
                                    </div>
                                    <div className="custom-form row">
                                        <div className="col-md-12">
                                            <label> TWITTER :</label>
                                            <input  {...register("twitter")} className="form-control" placeholder="Veuillez saisir le lien de votre profil sur Twitter" />
                                        </div>
                                    </div>
                                    <div className="custom-form row">
                                        <div className="col-md-6">
                                            <span className="suivant-text w-100 text-left pl-2">etape 1/3</span>
                                        </div>
                                        <div className="col-md-6">
                                            <input type="submit" value="Suivant" className="btn btn-b2b suivant" style={{width:"auto",height:"auto"}} />
                                        </div>
                                        {/* <div classNane="col-md-12">
                                          
                                            {errors.last_name?<span className="error d-block">NOM est obligatoire</span>:<></>}
                                            {errors.civilite?<span className="error d-block">Civilité est obligatoire</span>:<></>}
                                            {errors.first_name?<span className="error d-block">PRENOM est obligatoire</span>:<></>}
                                            {errors.etat_civil?<span className="error d-block">Etat civil est obligatoire</span>:<></>}
                                            {errors.date_naissance_j?<span className="error d-block">Date de naissance "Jours" est obligatoire</span>:<></>}
                                            {errors.date_naissance_m?<span className="error d-block">Date de naissance "Mois" est obligatoire</span>:<></>}
                                            {errors.date_naissance_a?<span className="error d-block">Date de naissance "Anneé" est obligatoire</span>:<></>}
                                            {errors.ville?<span className="error d-block">Pays est obligatoire</span>:<></>}   
                                            {errors.gouvernorat?<span className="error d-block">Gouvernorat est obligatoire</span>:<></>}
                                            {errors.addresse?<span className="error d-block">Adresse exacte est obligatoire</span>:<></>}
                                            {errors.conduite?<span className="error d-block">Permis de conduite est obligatoire</span>:<></>}
                                            {errors.phone?<span className="error d-block">GSM est obligatoire</span>:<></>}
                                            {errors.file?<span className="error d-block">Photo de profil est obligatoire</span>:<></>}
                                        </div> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>             
    );
}
