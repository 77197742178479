import React, { Component } from 'react';
import DataHome from '../../services/home';
import i18next from 'i18next';
import './style.css';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
class EntrepriseParRegion extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            items : [
                <ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,
                <ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,
                <ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>
            ]
        }
        DataHome.getRegions().then((res)=>{
            if(res.data.get){
                var items=[];
                var i=0;
                res.data.data.forEach(region => {
                    items.push(<Item id={i++} name={region.name} url={region.href} number={region.cont}/>);
                });
                setTimeout(() => {
                    this.setState({items:[]});
                    this.setState({items:items});
                }, 1000);
            }
        });
    }
    render() {
        const { t } = this.props;
        return ( 
        <>
            <div className="section-full content-inner overlay-black-dark" style={{backgroundImage:"url(/assets/img/entreprise-par-r.jpg)",backgroundPosition: "bottom",backgroundSize: "cover"}}>
                <div className="container">
                    <div className="section-head text-white text-center">
                        <h2 className="text-uppercase">{t("LISTE DES ENTREPRISES PAR RÉGION – B2B")}</h2>
                        <div className="dlab-separator-outer ">
                            <div className="dlab-separator bg-secondry style-skew"></div>
                        </div>
                    </div>
                    <div className="section-content ">
                        <ul className="list-categories row" style={{"list-style":"none"}}>
                            {this.state.items}
                        </ul> 
                    </div>
                </div>
            </div>
        </>
        );
    }
}
export default withTranslation()(React.memo(EntrepriseParRegion));

class Item extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            id : props.id,
            prod_name:props.name,
            prod_url:props.url,
            prod_number:props.number,
        }
    }
    render() {

        return ( 
            <>
            {
                this.state.prod_number>0 &&
            <li className="col-md-3 col-xs-12 cat-99">
                <Link to={"/"+i18next.language+this.state.prod_url} class="categories-wrapper"> 
                    <span className="categories-name">{this.state.prod_name !=="Côte d&#039;Ivoire" ?this.state.prod_name :"Côte d'Ivoire"}</span>
                    <span className="number-categories">{this.state.prod_number}</span>
                </Link>
            </li>
        }
        </>
        );
    }
}

class ItemLoader extends Component
{
    render() {

        return ( 
            <li className="col-md-3 col-xs-12 cat-99">
                <a href="javascript:void(0)" className="categories-wrapper region-loader"> 
                    <span className="categories-name"></span>
                    <span className="number-categories"></span>
                </a>
            </li>
        );
    }
}

