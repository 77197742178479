
import './style.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Experts from '../../services/experts';
import { Markup } from 'react-render-markup';
import MetaTags from 'react-meta-tags';
import Select from 'react-select'
import Pagination from "react-js-pagination";
import i18next from 'i18next';
import { b2bNameSite, b2bDescriptionSite } from '../../config.json';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga'
class Index extends Component {
    constructor(props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        const { innerWidth: width, innerHeight: height } = window;

        super(props)
        this.state = {
            items: [<ItemLoader />, <ItemLoader />, <ItemLoader />, <ItemLoader />, <ItemLoader />, <ItemLoader />, <ItemLoader />, <ItemLoader />],
            categorieoptions: [{ value: '', label: '' }],
            search_mot_cle: "",
            search_entreprises: 0,
            search_categorie: [],
            search_type_compte: [],
            search_type_emploi: [],
            search_experiences: [],
            search_niveau_etudes: [],
            search_regions: [],
            activePage: 1,
            totalItemsCount: 0,
            itemsPerPage: 12,
            type_compte: [],
            type_emploi: [],
            experiences: [],
            niveau_etudes: [],
            regions: [],
            progressPage: 0,
            showProgressPage: true,
            showfiltre: width > 700 ? "0" : "-100",
            l: localStorage.getItem("langb2b")
        }
        setInterval(() => {
            if (this.state.progressPage < 80) {
                this.setState({ progressPage: this.state.progressPage++ });
            }
        }, 100);
        Experts.getCategories().then((res) => {
            if (res.data.get) {
                var items = [{ value: '', label: this.props.t('Toutes les catégories') }];
                res.data.data.forEach(categorie => {
                    items.push({ value: categorie.id, label: categorie.name },);
                });
                this.setState({ categorieoptions: [] });
                this.setState({ categorieoptions: items });
            }
        });

        Experts.getExperts().then((res) => {
            this.updatedate(res);
            this.setState({ progressPage: 100 });
            setTimeout(() => {
                this.setState({ showProgressPage: false });
            }, 1000);
        });
    }
    changeSearch = (e) => {
        this.setState({
            search_mot_cle: e.target.value
        });
    }
    changeSearchCategories = (e) => {
        this.setState({
            search_categorie: e.value
        });
        Experts.getExperts(
            this.state.activePage,
            this.state.search_mot_cle,
            e.value,
            this.state.search_type_compte,
            this.state.search_type_emploi,
            this.state.search_experiences,
            this.state.search_niveau_etudes,
            this.state.search_regions
        ).then((res) => {
            this.updatedate(res);
        });
    }

    submitSearch = () => {
        Experts.getExperts(
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_categorie,
            this.state.search_type_compte,
            this.state.search_type_emploi,
            this.state.search_experiences,
            this.state.search_niveau_etudes,
            this.state.search_regions
        ).then((res) => {
            this.updatedate(res);
        });
    }
    showfiltre = () => {
        if (this.state.showfiltre == "-100") {
            this.setState({
                showfiltre: "0"
            })
        } else {
            this.setState({
                showfiltre: "-100"
            })
        }

    }
    handlePageChange(pageNumber) {

        this.setState({ activePage: pageNumber });
        this.initItems();
        Experts.getExperts(
            pageNumber,
            this.state.search_mot_cle,
            this.state.search_categorie,
            this.state.search_type_compte,
            this.state.search_type_emploi,
            this.state.search_experiences,
            this.state.search_niveau_etudes,
            this.state.search_regions).then((res) => {
                this.updatedate(res);
            });
    }
    updatedate(res) {
        if (res.data.get) {
            var items = [];
            res.data.data.forEach(expert => {
                if (expert.url != "")
                    items.push(<Item
                        t={this.props.t}
                        key={expert.id}
                        id={expert.id}
                        url={expert.url}
                        img={expert.img}
                        nom={expert.nom}
                        prenom={expert.prenom}
                        experience={expert.experience}
                        niveau={expert.niveau}
                        diplome={expert.diplome}
                    />);
            });
            this.setState({ items: [] });
            this.setState({ items: items });
            this.setState({
                items: items,
                type_compte: res.data.type_compte,
                type_emploi: res.data.type_emploi,
                experiences: res.data.experiences,
                niveau_etudes: res.data.niveau_etudes,
                regions: res.data.regions,
                totalItemsCount: res.data.nbposts
            });
        }
    }
    changeTypeCompte(event) {
        var value = Array.from(this.state.search_type_compte);
        if (event.target.checked == true) {
            value.push(event.target.value);
            this.setState({ search_type_compte: value });
        } else {
            var index = value.indexOf(event.target.value)
            if (index !== -1) {
                value.splice(index, 1);
            }
            this.setState({ search_type_compte: value });
        }

        Experts.getExperts(
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_categorie,
            value,
            this.state.search_type_emploi,
            this.state.search_experiences,
            this.state.search_niveau_etudes,
            this.state.search_regions).then((res) => {
                this.updatedate(res);
            });
    }
    changeTypeEmploi(event) {
        var value = Array.from(this.state.search_type_emploi);
        if (event.target.checked == true) {
            value.push(event.target.value);
            this.setState({ search_type_emploi: value });
        } else {
            var index = value.indexOf(event.target.value)
            if (index !== -1) {
                value.splice(index, 1);
            }
            this.setState({ search_type_emploi: value });
        }

        Experts.getExperts(
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_categorie,
            this.state.search_type_compte,
            value,
            this.state.search_experiences,
            this.state.search_niveau_etudes,
            this.state.search_regions).then((res) => {
                this.updatedate(res);
            });
    }
    changeExperiences(event) {
        var value = Array.from(this.state.search_experiences);
        if (event.target.checked == true) {
            value.push(event.target.value);
            this.setState({ search_experiences: value });
        } else {
            var index = value.indexOf(event.target.value)
            if (index !== -1) {
                value.splice(index, 1);
            }
            this.setState({ search_experiences: value });
        }

        Experts.getExperts(
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_categorie,
            this.state.search_type_compte,
            this.state.search_type_emploi,
            value,
            this.state.search_niveau_etudes,
            this.state.search_regions).then((res) => {
                this.updatedate(res);
            });
    }
    changeNiveauEtudes(event) {
        var value = Array.from(this.state.search_niveau_etudes);
        if (event.target.checked == true) {
            value.push(event.target.value);
            this.setState({ search_niveau_etudes: value });
        } else {
            var index = value.indexOf(event.target.value)
            if (index !== -1) {
                value.splice(index, 1);
            }
            this.setState({ search_niveau_etudes: value });
        }

        Experts.getExperts(
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_categorie,
            this.state.search_type_compte,
            this.state.search_type_emploi,
            this.state.search_experiences,
            value,
            this.state.search_regions).then((res) => {
                this.updatedate(res);
            });
    }
    changeRegions(event) {
        var value = Array.from(this.state.search_regions);
        if (event.target.checked == true) {
            value.push(event.target.value);
            this.setState({ search_regions: value });
        } else {
            var index = value.indexOf(event.target.value)
            if (index !== -1) {
                value.splice(index, 1);
            }
            this.setState({ search_regions: value });
        }

        Experts.getExperts(
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_categorie,
            this.state.search_type_compte,
            this.state.search_type_emploi,
            this.state.search_experiences,
            this.state.search_niveau_etudes,
            value).then((res) => {
                this.updatedate(res);
            });
    }
    initItems() {
        // this.setState({items : []});
        // window.scrollTo(0, 0);
        // this.setState({items : [
        //     <ItemLoader/>,
        //     <ItemLoader/>,
        //     <ItemLoader/>,
        //     <ItemLoader/>,
        //     <ItemLoader/>,
        //     <ItemLoader/>,
        //     <ItemLoader/>,
        //     <ItemLoader/>
        // ]});
    }
    //****************************** Load update of change language ***************
    componentDidUpdate() {
        if (this.state.l !== localStorage.getItem("langb2b")) {
            Experts.getExperts().then((res) => {
                this.updatedate(res);
                this.setState({ progressPage: 100 });
                setTimeout(() => {
                    this.setState({ showProgressPage: false });
                }, 1000);
            });
            this.setState({
                l: localStorage.getItem("langb2b")
            })
        }

    }
    render() {
        var type_compte = [];
        if (this.state.type_compte) {
            this.state.type_compte.forEach(element => {
                type_compte.push(
                    <div>
                        <input type="checkbox" className="click-submit" id={"checkbox" + element.slug + "_" + element.id} value={element.slug} onChange={this.changeTypeCompte.bind(this)} />
                        <label for={"checkbox" + element.slug + "_" + element.id} className="l" >{element.nom}</label>
                        <br />
                    </div>)
            });
        }

        var type_emploi = [];
        if (this.state.type_emploi) {
            this.state.type_emploi.forEach(element => {
                type_emploi.push(
                    <div>
                        <input type="checkbox" className="click-submit" id={"checkbox" + element.slug + "_" + element.id} value={element.id} onChange={this.changeTypeEmploi.bind(this)} />
                        <label for={"checkbox" + element.slug + "_" + element.id} className="l" >{element.nom}</label>
                        <br />
                    </div>)
            });
        }

        var experiences = [];
        if (this.state.experiences) {
            this.state.experiences.forEach(element => {
                experiences.push(
                    <div>
                        <input type="checkbox" className="click-submit" id={"checkbox" + element.slug + "_" + element.id} value={element.id} onChange={this.changeTypeEmploi.bind(this)} />
                        <label for={"checkbox" + element.slug + "_" + element.id} className="l" >{element.nom}</label>
                        <br />
                    </div>)
            });
        }

        var niveau_etudes = [];
        if (this.state.niveau_etudes) {
            this.state.niveau_etudes.forEach(element => {
                niveau_etudes.push(
                    <div>
                        <input type="checkbox" className="click-submit" id={"checkbox" + element.slug + "_" + element.id} value={element.id} onChange={this.changeTypeEmploi.bind(this)} />
                        <label for={"checkbox" + element.slug + "_" + element.id} className="l" >{element.nom}</label>
                        <br />
                    </div>)
            });
        }

        var regions = [];
        if (this.state.regions) {
            this.state.regions.forEach(element => {
                regions.push(
                    <div>
                        <input type="checkbox" className="click-submit" id={"checkbox" + element.slug + "_" + element.id} value={element.id} onChange={this.changeTypeEmploi.bind(this)} />
                        <label for={"checkbox" + element.slug + "_" + element.id} className="l" >{element.nom}</label>
                        <br />
                    </div>)
            });
        }
        const { t } = this.props
        return (<>
            {this.state.showProgressPage ? <div class="progress-div" style={{ width: "100%" }}><div style={{ width: this.state.progressPage + "%" }} class="progress"></div></div> : <></>}
            <MetaTags>
                <title>{t("Les meilleurs profils des experts en France et à l’international")} | {b2bNameSite} | {localStorage.getItem("langb2b") === "en" ? "B2B marketplace and B2B networking platform in France and abroad" : b2bDescriptionSite}</title>
            </MetaTags>
            <div className="page-content">
                <section id="list-places-wrapper" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 widget de_widget_categories prod-list" id="search-places">
                                <form role="search" className="place_search_form" action="" method="GET">
                                    <div className="row block-search-all ml-0">
                                        <div className="row col-md-11 col-10 de-search-form border-block" id="search-places" style={{ "margin-left": "0px" }}>
                                            <div className="col-md-8 block-search-keyword">
                                                <div className="de-scontrol">
                                                    <input type="text" name="mot_cle" onChange={this.changeSearch} className="keyword-search" placeholder={t("Entrez un mot-clé") + "..."} value={this.state.search_mot_cle} />
                                                </div>
                                            </div>
                                            <div className="col-md-4  block-search-categorie">
                                                <div className="de-scontrol">
                                                    <Select options={this.state.categorieoptions} placeholder={t("Toutes les catégories")} onChange={this.changeSearchCategories} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 col-2 block-search-btn" style={{ "padding-right": "0" }}>
                                            <button className="btn btn-primary search-btn" type="button" onClick={this.submitSearch} style={{ "background-color": "#ED2829", "border": "none", "height": "65px", "width": "100%", marginBottom: "1px" }}>
                                                <i className="fa fa-search fa-lg"></i>
                                            </button>
                                            <button className="btn btn-primary show-mobile" type="button" onClick={this.showfiltre} style={{ "background-color": "#ED2829", "border": "none", "height": "65px", "width": "100%", borderRadius: "0px 0px 25px 25px" }}>
                                                <i className="fa fa-filter fa-lg"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-2 border-block block-fillter-expert block-fillter-mobile" style={{ left: this.state.showfiltre + "%" }}>
                                <div className="row">
                                    {type_compte ?
                                        <div className="col-md-12">
                                            <h4 className="h4 ">{t("Type de compte")} : </h4>
                                            {type_compte}
                                        </div>
                                        : <></>}
                                    {type_emploi ?
                                        <div className="col-md-12">
                                            <h4 className="h4 ">{t("Type de contrat")} : </h4>
                                            {type_emploi}
                                        </div>
                                        : <></>}
                                    {experiences ?
                                        <div className="col-md-12">
                                            <h4 className="h4 ">{t("Niveau d'expérience")} : </h4>
                                            {experiences}
                                        </div>
                                        : <></>}
                                    {niveau_etudes ?
                                        <div className="col-md-12">
                                            <h4 className="h4 ">{t("Niveau d'étude")} : </h4>
                                            {niveau_etudes}
                                        </div>
                                        : <></>}
                                    {regions ?
                                        <div className="col-md-12">
                                            <h4 className="h4 ">{t("Gouvernorat")} : </h4>
                                            {regions}
                                        </div>
                                        : <></>}

                                </div>
                            </div>
                            <div className="col-md-10">
                                {/* <div className="col-md-12 widget de_widget_categories prod-list" style={{"margin-bottom": "10px","text-transform":  "uppercase","text-align": "center"}}><h3></h3></div> */}
                                <div className="col-md-12">
                                    <ul className="list-places list-posts row" style={{ "list-style": "none" }}>
                                        {this.state.items}
                                    </ul>
                                    <div className="paginations-wrapper">
                                        <div className="paginations">
                                            {this.state.totalItemsCount > this.state.itemsPerPage ?
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={this.state.itemsPerPage}
                                                    totalItemsCount={this.state.totalItemsCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChange.bind(this)}
                                                />
                                                : <></>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>);
    }
}


export default withTranslation()(Index);


class Item extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: props.id,
            url: props.url.replace("experts/", "expert/"),
            img: props.img,
            nom: props.nom,
            prenom: props.prenom,
            experience: props.experience,
            niveau: props.niveau,
            diplome: props.diplome,
        }
    }

    render() {

        return (
            <li className="block-company-mobile place-item post-1809 place type-place status-publish has-post-thumbnail hentry place_category-industrie location-tunis col-md-3 col-xs-6" style={{ "padding-left": "20", "padding-right": "20px" }}>
                <div className="place-wrapper block-item-expert" style={{ "text-align": "center", "box-shadow": "none", "border": "1px solid #a9aaaa", "padding": "5px", "background-color": "white", height: "100%" }}>

                    <Link to={"/" + i18next.language + this.state.url} style={{ minHeight: "180px", display: "block" }}>
                        <figure className="expert-image">
                            <img onContextMenu={(e) => e.preventDefault()} className="profile-pctiure" src={this.state.img} alt={this.state.prenom + " " + this.state.nom} />
                        </figure>

                        <span className="survey-name text-uppercase" style={{ color: '#000' }}>
                            {this.state.prenom + " " + this.state.nom}
                        </span>
                        <span className="survey-country grid-only">
                            {this.state.experience}
                        </span><br />
                        <span className="survey-completes" style={{ color: '#ED2829' }}>
                            {this.state.niveau}
                        </span>
                        <br />
                        <span className="survey-completes" style={{ color: '#000000' }}>
                            {this.state.diplome}
                        </span>
                    </Link>
                    <div className="block-expert-voir">
                        <Link className="btn-voir-expert" to={"/" + i18next.language + this.state.url} >{this.props.t("Voir")} </Link>
                    </div>
                </div>
            </li>
        );
    }
}
class ItemLoader extends Component {
    render() {

        return (
            <li className="block-company-mobile place-item post-1809 place type-place status-publish has-post-thumbnail hentry place_category-industrie location-tunis col-md-3 col-xs-6" style={{ "padding-left": "0", "padding-right": "10px" }}>
                <div className="place-wrapper block-item-expert" style={{ "box-shadow": "none", "border": "1px solid #a9aaaa", "padding": "5px", "background-color": "white" }}>
                    <div className="hidden-img" >
                        <div className="img-place loader-expert-image" ><p></p></div>
                    </div>
                    <div className="title-place notranslate loader-entreprise-title"><p></p></div>
                    <div className="block-expert-voir">
                        <a className="btn-voir-expert" href="#" >Voir </a>
                    </div>
                </div>
            </li>
        );
    }
}
