

import './style.css';

import React, { Component } from 'react';

import Enteprises from '../../services/enteprises';

import { Link,NavLink } from 'react-router-dom';

import { Markup } from 'react-render-markup';

import MetaTags from 'react-meta-tags';

import Select from 'react-select'

import {prefixe,b2bNameSite,b2bDescriptionSite} from '../../config.json';

import Pagination from "react-js-pagination";

import i18next from 'i18next';

import { useParams } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

import ReactGA from 'react-ga'

function Index(props){

    let { region } = useParams();

    const { t } = props;

    return (

        <PageEnteprises t={t} region={region} />

    );

}

export default withTranslation()(Index);



class PageEnteprises extends Component

{



    constructor (props) {

        ReactGA.pageview(window.location.pathname);

        window.scrollTo(0, 0);

        super(props)

        const urlParams = new URLSearchParams(window.location.search);

        let categorie = "";
        if(urlParams.get('categorie')){
            categorie = urlParams.get('categorie').toUpperCase();
        }

        const region = urlParams.get('region');

        this.state = {

            items : [<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>],

            regionoptions : [{ value: '', label: '' }],

            categorieoptions : [{ value: '', label: '' }],

            search_categorie : categorie ? categorie : 0,

            defaultValueCategorie : null,

            defaultValueRegion : null,

            search_mot_cle : "" ,

            search_region : region ? region : 0,

            activePage : 1 ,

            totalItemsCount : 0,

            itemsPerPage :20,

            progressPage : 0,

            styleprogressPage:{width: "0%"},

            showProgressPage:true,

            DataEntreprises:[],

            DataAllEntreprises:[]

        }

        

        setInterval(() => {

            if(this.state.progressPage!=100){

                this.setState({

                    progressPage:this.state.progressPage++,

                    styleprogressPage:{width: this.state.progressPage+"%"},

                });

                

            }

            

        }, 1);

        Enteprises.getRegions().then((res)=>{

            if(res.data.get){

                var items = [{ value: '0', label: 'Toutes les régions' ,selected:true,slug:""}];

                res.data.data.forEach(region => {

                    if(parseInt(region.id) == parseInt(this.state.search_region) || region.slug == this.props.region){

                        this.setState({defaultValueRegion : { value: region.id, label: region.name, slug:region.slug } ,search_region:region.id});

                        this.filterEntreprises(this.state.search_mot_cle,this.state.search_categorie,region.id);

                    }

                    region.childrens.forEach(children => {

                        if(parseInt(children.id) == parseInt(this.state.search_region) || children.slug == this.props.region ){

                            this.setState({defaultValueRegion : { value: children.id, label: children.name , slug:children.slug },search_region:children.id });

                            this.filterEntreprises(this.state.search_mot_cle,this.state.search_categorie,children.id);

                        }

                        items.push({ value: children.id, label: children.name , slug:children.slug });

                    }); 

                    items.push({ value: region.id, label: region.name , slug:region.slug });

                }); 

                this.setState({regionoptions : []});

                this.setState({regionoptions : items});

                

            }

        });

        var getFerst=false;

        Enteprises.getEntreprisesAll(-1).then((res)=>{

            if(res.data.get){

                this.chargeEntreprises(res);

                this.filterEntreprises(this.state.search_mot_cle,this.state.search_categorie,this.state.search_region);

                getFerst=true;

            }

        });

        Enteprises.getEntreprisesAll(20).then((res)=>{

            if(res.data.get && !getFerst){

                this.chargeEntreprises(res);

                this.filterEntreprises(this.state.search_mot_cle,this.state.search_categorie,this.state.search_region);

                

            }

        });

        

        Enteprises.getCategories().then((res)=>{

            if(res.data.get){

                var items = [{ value: '0', label: 'Toutes les catégories' }];

                res.data.data.forEach(categorie => {

                    if(categorie.name == this.state.search_categorie ){

                        this.setState({defaultValueCategorie : { value: categorie.name, label: categorie.name } });

                    }

                    items.push({ value: categorie.name, label: categorie.name });

                });

                

                this.setState({categorieoptions : []});

                this.setState({categorieoptions : items});

               

                //console.log(items);

            }

        });

        

       

    }

    chargeEntreprises = (res)=>{

        console.log(res);

        var items = [];

        var DataEntreprises = [];

        res.data.data.forEach(enteprise => {



            DataEntreprises.push({

                "id":enteprise.id,

                "url":enteprise.url,

                "img":enteprise.img,

                "title":enteprise.title,

                "phone":enteprise.phone,

                "phone_title" : enteprise.phone_title,

                "address":enteprise.address,

                "pack":enteprise.pack,

                "nb_prod" :enteprise.nb_prod,

                "region" :enteprise.region,

                "categories" :enteprise.categories,

                "indicateur" :enteprise.indicateur,

                "pays_icone" :enteprise.pays_icone,

                "pays_name" :enteprise.pays_name,

                "recherche_mot_cle" :enteprise.recherche_mot_cle,

            })

            if(items.length < this.state.itemsPerPage){

                items.push(<Item 

                    t={this.props.t}

                    enteprise={enteprise}

                    sponsoriser={false}

                    key={enteprise.id} 

                    id={enteprise.id} 

                    url={enteprise.url} 

                    img={enteprise.img} 

                    title={enteprise.title} 

                    phone={enteprise.phone}

                    phone_title={enteprise.phone_title}

                    address={enteprise.address}

                    pack={enteprise.pack}

                    nb_prod={enteprise.nb_prod}

                    />);

            }   

        });



        this.setState({

            progressPage : 100,

            styleprogressPage:{width: "100%"},

        });

        setTimeout(() => {

            this.setState({showProgressPage:false}); 

        }, 1000); 

        this.setState({items : []});

        this.setState({

            items : items,

            totalItemsCount : res.data.nbposts,

            DataEntreprises : DataEntreprises,

            DataAllEntreprises : DataEntreprises,

        });

    }

    filterEntreprises = (mot_cle,categorie,region)=>{

        categorie = categorie;

        region = parseInt(region);

       

        this.setState({

            activePage : 1 ,

            items : []

        });

        var  DataEntreprises = [];

        if(mot_cle!="" && categorie!=0 && region!=0){ //111

            this.state.DataAllEntreprises.forEach(entreprise => {

                const title = entreprise.title.toLowerCase();

                const serche = mot_cle!=""?mot_cle.toLowerCase():"";

                if(entreprise.categories.includes(categorie) && entreprise.region == region && title.indexOf(serche)!=-1){

                    DataEntreprises.push(entreprise);

                }

            });

        }else if(categorie!=0 && region!=0){//110

            this.state.DataAllEntreprises.forEach(entreprise => {

                if(entreprise.categories.includes(categorie) && entreprise.region == region ){

                    DataEntreprises.push(entreprise);

                }

            });

        }else if(mot_cle!="" && region!=0){//101

            this.state.DataAllEntreprises.forEach(entreprise => {

                const title = entreprise.title.toLowerCase();

                const serche = mot_cle!=""?mot_cle.toLowerCase():"";

                if(title.indexOf(serche)!=-1 && entreprise.region == region ){

                    DataEntreprises.push(entreprise);

                }

            });

        }else if(mot_cle!="" && categorie!=""){//011

            this.state.DataAllEntreprises.forEach(entreprise => {

                const title = entreprise.title.toLowerCase();

                const serche = mot_cle!=""?mot_cle.toLowerCase():"";

                if(title.indexOf(serche)!=-1 && entreprise.categories.includes(categorie) ){

                    DataEntreprises.push(entreprise);

                }

            });

        }else if(categorie!=""){//010

            this.state.DataAllEntreprises.forEach(entreprise => {

                if(entreprise.categories.includes(categorie) ){

                    DataEntreprises.push(entreprise);

                }

            });

        }else if(mot_cle!=""){//001

            this.state.DataAllEntreprises.forEach(entreprise => {

                const title = entreprise.title.toLowerCase();

                const serche = mot_cle!=""?mot_cle.toLowerCase():"";

                if(title.indexOf(serche)!=-1){

                    DataEntreprises.push(entreprise);

                }

            });

        }else if(region!=0){//100

            this.state.DataAllEntreprises.forEach(entreprise => {

                if(entreprise.region == region ){

                    DataEntreprises.push(entreprise);

                }

            });

        }else{

            DataEntreprises = this.state.DataAllEntreprises;

        }



        var items = [];

        this.state.DataAllEntreprises.forEach(enteprise => {

            var entreprisesponsoriser=false;

            if(enteprise.recherche_mot_cle){

                enteprise.recherche_mot_cle.forEach(cle => {

                    if(mot_cle==cle){

                        entreprisesponsoriser=true;

                    }

                }); 

            }

           

            if(entreprisesponsoriser){

                items.push(<Item 

                    t={this.props.t}

                    sponsoriser={true}

                    enteprise={enteprise}

                    key={enteprise.id} 

                    id={enteprise.id} 

                    url={enteprise.url} 

                    img={enteprise.img} 

                    title={enteprise.title} 

                    phone={enteprise.phone}

                    phone_title={enteprise.phone_title}

                    address={enteprise.address}

                    pack={enteprise.pack}

                    nb_prod={enteprise.nb_prod}

                    />);

            }

        });

        DataEntreprises.forEach(enteprise => {

            if(items.length < this.state.itemsPerPage){

                items.push(<Item 

                    t={this.props.t}

                    sponsoriser={false}

                    enteprise={enteprise}

                    key={enteprise.id} 

                    id={enteprise.id} 

                    url={enteprise.url} 

                    img={enteprise.img} 

                    title={enteprise.title} 

                    phone={enteprise.phone}

                    phone_title={enteprise.phone_title}

                    address={enteprise.address}

                    pack={enteprise.pack}

                    nb_prod={enteprise.nb_prod}

                    />);

            }   

        });

        

        this.setState({

            items : items,

            DataEntreprises : DataEntreprises,

            totalItemsCount : DataEntreprises.length

        });

    }

    changeSearch = (e)=>{

        this.setState({

            search_mot_cle : e.target.value

        });

        this.filterEntreprises(e.target.value,this.state.search_categorie,this.state.search_region);

    }



    changeSearchCategories = (e)=>{

        this.state.categorieoptions.forEach(categorie => {

            if(parseInt(categorie.value) == parseInt( e.value ) ){

                this.setState({defaultValueCategorie : { value: categorie.value, label: categorie.label } });

            }

        });

        this.setState({

            search_categorie : e.value

        });

        this.filterEntreprises(this.state.search_mot_cle,e.value,this.state.search_region);

    }

    changeSearchRegion = (e)=>{

        var slug = "";

        var id = "";

        this.state.regionoptions.forEach(region => {

            if(parseInt(region.value) == parseInt( e.value ) ){

                this.setState({defaultValueRegion : { value: region.value, label: region.label } });

                slug = region.slug;

                id = region.value;

                

            }

        });

        this.setState({

            search_region : e.value

        });

        this.filterEntreprises(this.state.search_mot_cle,this.state.search_categorie,e.value); 

        if(slug !="" && id !=0){

            //window.history.pushState('', 'region', 'region/'+slug+'?region='+id);

            const nextURL = window.location.origin+'/'+i18next.language+'/region/'+slug;

            const nextTitle = 'My new page title';

            const nextState = { additionalInformation: 'Updated the URL with JS' };

            window.history.pushState(nextState, nextTitle, nextURL);

            window.history.replaceState(nextState, nextTitle, nextURL);

        }else{

            const nextURL = window.location.origin+'/'+i18next.language+'/entreprises/';

            const nextTitle = 'My new page title';

            const nextState = { additionalInformation: 'Updated the URL with JS' };

            window.history.pushState(nextState, nextTitle, nextURL);

            window.history.replaceState(nextState, nextTitle, nextURL);

        }

        

    }

    submitSearch= ()=>{

        this.filterEntreprises(this.state.search_mot_cle,this.state.search_categorie,this.state.search_region);

    }

    handlePageChange(pageNumber) {

        

        this.setState({activePage: pageNumber});

        this.initItems();

        const items = [];

        var i = 0;

        this.state.DataEntreprises.forEach(enteprise => {

            if(items.length < this.state.itemsPerPage && i>=(pageNumber*this.state.itemsPerPage-this.state.itemsPerPage)){

                items.push(<Item 

                    t={this.props.t}

                    enteprise={enteprise}

                    key={enteprise.id} 

                    id={enteprise.id} 

                    url={enteprise.url} 

                    img={enteprise.img} 

                    title={enteprise.title} 

                    phone={enteprise.phone}

                    phone_title={enteprise.phone_title}

                    address={enteprise.address}

                    pack={enteprise.pack}

                    nb_prod={enteprise.nb_prod}

                    />);

            }

            i++;

        }); 

        this.setState({items : []});

        this.setState({items : items});

      

    }

    initItems(){

        window.scrollTo(0, 0);

        this.setState({items : [

            <ItemLoader/>,

            <ItemLoader/>,

            <ItemLoader/>,

            <ItemLoader/>,

            <ItemLoader/>,

            <ItemLoader/>,

            <ItemLoader/>,

            <ItemLoader/>

        ]});

    }

  

    render() {

        const { t } = this.props;

        return ( 

            <>



            {this.state.showProgressPage?<div className="progress-div" style={{width: "100%"}}><div style={this.state.styleprogressPage} className="progress"></div></div>:<></>}

            <MetaTags>

                <title>{t("Annuaire des Entreprises")} | {b2bNameSite} | {b2bDescriptionSite}</title>

            </MetaTags>

            <div className="page-content liste-entreprises">

                <section id="list-places-wrapper" >

                    <div className="container">

                        <div className="col-md-12 widget de_widget_categories prod-list" id="search-places">

                            <form role="search" className="place_search_form" action="" method="GET">

                                <div className="row block-search-all pr-3">

                                    <div className="row col-md-12 de-search-form" id="search-places" style={{"margin-left": "0px"}}>

                                        <div className="col-md-6 block-search-keyword">

                                            <div className="de-scontrol">

                                                <input type="text" onChange={this.changeSearch} name="mot_cle" className="keyword-search" placeholder={t("Entrez un mot-clé")+" ..."} value={this.state.search_mot_cle} />

                                            </div>

                                        </div>

                                        <div className="col-md-3 block-search-entreprises">

                                            <div className="de-scontrol">

                                                <Select options={this.state.regionoptions} placeholder={t("Toutes les régions")} value = {this.state.defaultValueRegion} onChange={this.changeSearchRegion}/>

                                            </div>

                                        </div>

                                        <div className="col-md-3  block-search-categorie">

                                            <div className="de-scontrol">

                                                <Select options={this.state.categorieoptions} placeholder={t("Toutes les catégories")} value = {this.state.defaultValueCategorie} onChange={this.changeSearchCategories}/>

                                            </div>

                                        </div>

                                    </div>

                                    {/* <div className="col-md-1 block-search-btn" style={{"padding-right":"0"}}>

                                        <button className="btn btn-primary" type="button" onClick={this.submitSearch} style={{"background-color":"#ED2829","border":"none","height":"65px","width": "100%"}}>

                                            <i className="fa fa-search fa-lg"></i>

                                        </button>

                                    </div> */}

                                </div>

                            </form>

                        </div>

                        <div className="col-md-12 widget de_widget_categories prod-list" style={{"margin-bottom": "10px","text-transform":  "uppercase","text-align": "center"}}><h3></h3></div>

                        <ul className="list-places list-posts row" style={{"list-style":"none"}}>

                            {this.state.items}

                        </ul>

                        <div className="paginations-wrapper">

                            <div className="paginations">

                                {this.state.totalItemsCount>this.state.itemsPerPage?

                                <Pagination

                                t={t}

                                activePage={this.state.activePage}

                                itemsCountPerPage={this.state.itemsPerPage}

                                totalItemsCount={this.state.totalItemsCount}

                                pageRangeDisplayed={5}

                                onChange={this.handlePageChange.bind(this)}

                                />

                                :<></>}

                                {/* <span aria-current="page" className="page-numbers current">1</span>

                                <a className="page-numbers" hhref="#entreprises/page/2/">2</a>

                                <a className="page-numbers" href="#entreprises/page/3/">3</a>

                                <span className="page-numbers dots">…</span>

                                <a className="page-numbers" href="#entreprises/page/23/">23</a>

                                <a className="next page-numbers" href="#entreprises/page/2/"> »</a> */}

                            </div>

                        </div>

                    </div>

                </section>

            </div>

            </>

        );

    }

}



class Item extends Component

{

    constructor (props) {

        super(props);  

        this.state = {

            sponsoriser: props.sponsoriser,

            id : props.id,

            entreprise_name:props.title,

            entreprise_url:props.url,

            entreprise_image:props.img,

            entreprise_tel:props.phone,

            entreprise_phone_title:props.phone_title,

            entreprise_addres:props.address,

            entreprise_pack:props.pack,

            entreprise_nb_prod:props.nb_prod,

            pays:props.enteprise.pays_name,

            paysIcone:props.enteprise.pays_icone,

            indicateur:props.enteprise.indicateur

        }

        console.log(props.enteprise);

    }

  

    render() {

        const { t } = this.props;

        return ( 

            <li className=" block-company-mobile place-item post-1809 place type-place status-publish has-post-thumbnail hentry place_category-industrie location-tunis col-md-3 col-xs-6" style={{"padding-left":"0px","padding-right":"11px"}}>

            <div className="place-wrapper" style={{"background-color": "#ffffff","border": "1px solid #b8c7c8","box-shadow": "none",position:"relative"}}>

           

                {/* {this.state.entreprise_pack=="platinium"?<div className='contint-pack platinium'><i></i></div>:<></>  }

                {this.state.entreprise_pack=="premium"?<div className='contint-pack premium'><i></i></div>:<></>  } */}

                {this.state.sponsoriser?<div className='contint-pack sponsoriser'>Annonce</div>:<></>  }



                <Link to={"/"+i18next.language+this.state.entreprise_url} title={this.state.entreprise_name+"--"+this.state.entreprise_pack}>

                    <h2 className="title-place notranslate" style={{width:"calc(100% - 40px)"}}><Markup markup={this.state.entreprise_name}/></h2>

                </Link>

                <div className="hidden-img">

                    <Link to={"/"+i18next.language+this.state.entreprise_url} className="img-place" title={this.state.entreprise_name}>

                        <img  onContextMenu={(e)=> e.preventDefault()} className="lazy" src={this.state.entreprise_image} style={{"display":"inline"}} />

                    </Link>

                </div>

                <div className="address-place" adresse itemProp="addressLocality" style={{textAlign: 'center', color: '#000', fontWeight: 700, padding: '10px 0'}}>

                    <img src={this.state.paysIcone} style={{width: '20px'}} />{this.state.pays}     

                </div>

                <div className="place-detail-wrapper details-list" style={{"height":"auto"}}>

                    <span className="address-place" adresse="" itemprop="addressLocality"><i className="fa fa-phone"></i> {t(this.state.entreprise_phone_title)} : {this.state.indicateur} {this.state.entreprise_tel}</span>

                    <span className="address-place" adresse="" itemprop="addressLocality"><i className="fa fa-map-marker"></i><Markup markup={this.state.entreprise_addres}/></span>

                    <span className="address-place" adresse="" itemprop="addressLocality"><i className="fa fa-newspaper-o"></i>{this.state.entreprise_nb_prod} {t("produits")}</span>

                </div>

            </div>

        </li>

        );

    }

}



class ItemLoader extends Component

{

    render() {



        return ( 

            <li className=" block-company-mobile place-item post-1809 place type-place status-publish has-post-thumbnail hentry place_category-industrie location-tunis col-md-3 col-xs-6" style={{"padding-left":"0px","padding-right":"11px"}}>

            <div className="place-wrapper" style={{"background-color": "#ffffff","border": "1px solid #b8c7c8","box-shadow": "none"}}>

                <div className="title-place notranslate loader-entreprise-title"><p></p></div>

                <div className="hidden-img">

                    <div className="img-place loader-entreprise-image" ><p></p></div>

                </div>

                <div className="place-detail-wrapper details-list" style={{"height":"auto"}}>

                    <span className="address-place" adresse="" itemprop="addressLocality"><i className="fa fa-phone"></i> Tél : {prefixe} <span className="loader-entreprise-tel-add-prod"></span></span>

                    <span className="address-place" adresse="" itemprop="addressLocality"><i className="fa fa-map-marker"></i> <span className="loader-entreprise-tel-add-prod"></span></span>

                    <span className="address-place" adresse="" itemprop="addressLocality"><i className="fa fa-newspaper-o"></i> <span className="loader-entreprise-tel-add-prod"></span></span>

                </div>

            </div>

        </li>

        );

    }

}

