import './style.css';
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import Auth from '../../services/Auth';
import GoogleLogin from 'react-google-login';
import { GoogleKey, b2bNameSite, b2bDescriptionSite } from "../../config.json";
import { Redirect } from 'react-router';
import i18next from 'i18next';
import ReactGA from 'react-ga'
export default class Index extends Component {
    constructor(props) {
        ReactGA.pageview(window.location.pathname);
        super(props)
        this.state = {
            isLogin: false
        }
        Auth.isLogin().then((res) => {
            if (res.data != false) {
                this.setState({ isLogin: true });
            } else {
                this.setState({ isLogin: false });
            }
        });
    }
    render() {
        if (this.state.isLogin) {
            return (<Redirect to={"/" + i18next.language} />);
        } else {
            return (<CompteExpert />);
        }
    }
}
class CompteExpert extends Component {

    constructor(props) {
        window.scrollTo(0, 0);
        super(props)
        this.state = {
            showConfirmerPassword: "password",
            showPassword: "password",
            username: "",
            password: "",
            confirmerPassword: "",
            showErrorMessage: false,
            errorMessage: "",
            textLogin: <>S'inscrire</>,
            redirect: <></>
        }
    }
    responseGoogle = (response) => {
        console.log(response);
        this.setState({ username: response.profileObj.email });
    }
    changeUsername = (e) => {
        this.setState({ username: e.target.value });
    }
    changePassword = (e) => {
        this.setState({
            showErrorMessage: false,
            errorMessage: "",
            password: e.target.value
        });
    }
    changeConfirmerPassword = (e) => {
        this.setState({
            showErrorMessage: false,
            errorMessage: "",
            confirmerPassword: e.target.value
        });
    }
    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    submitForm = () => {
        if (this.state.password == "" || this.state.username == "") {
            this.setState({
                showErrorMessage: true,
                errorMessage: "Attention; Le nom d'utilisateur et le mot de passe obligatoire!",
            });
        } else if (this.state.password == this.state.username) {
            this.setState({
                showErrorMessage: true,
                errorMessage: "Attention; Le nom d'utilisateur et le mot de passe identique",
            });
        } else {
            if (this.state.password != this.state.confirmerPassword) {
                this.setState({
                    showErrorMessage: true,
                    errorMessage: "Attention; Les 02 Mots De Passe Ne Sont Pas Identiques !",
                });
            } else if (this.validateEmail(this.state.username)) {
                this.setState({
                    showErrorMessage: false,
                    errorMessage: "",
                });
                this.setState({ textLogin: <span className='loading-login'></span> });
                Auth.registerExpert(this.state.username, this.state.password).then((res) => {
                    console.log(res.data.reponse);
                    if (res.data.code == 1) {
                        Auth.login(this.state.username, this.state.password).then((res) => {
                            if (res.data.code == 1) {
                                localStorage.setItem('tokenb2b', res.data.token);
                                window.location.href = "/" + i18next.language + "/expert/inscription";
                            } else {
                                localStorage.setItem('tokenb2b', "");
                                this.setState({ redirect: <Redirect to={"/" + i18next.language + "/signin"} /> });
                            }
                        });
                    } else {
                        if (res.data.reponse == "email_exist") {
                            this.setState({
                                errorMessage: "Cette adresse email est déjà associée à un compte sur « " + b2bNameSite + " ».",
                                showErrorMessage: true,
                                textLogin: <>S'inscrire</>
                            });
                            return false;
                        } else {
                            this.setState({
                                errorMessage: "Erreur de Connexion",
                                showErrorMessage: true,
                                textLogin: <>S'inscrire</>
                            });
                            return false;
                        }
                    }
                })
            } else {
                this.setState({
                    errorMessage: "Attention;Cette adresse email est incorrect",
                    showErrorMessage: true,
                    textLogin: <>S'inscrire</>
                });
            }
        }

    }
    clickShowConfirmerPassword = (e) => {
        if (this.state.showConfirmerPassword == "password") {
            this.setState({ showConfirmerPassword: "text" });
        } else {
            this.setState({ showConfirmerPassword: "password" });
        }
    }
    clickShowPassword = (e) => {
        if (this.state.showPassword == "password") {
            this.setState({ showPassword: "text" });
        } else {
            this.setState({ showPassword: "password" });
        }
    }
    render() {
        return (
            <>
                {this.state.redirect}
                <MetaTags>
                    <title>{localStorage.getItem("langb2b") === "fr" ? "Créez votre compte expert" : "Create your expert account"} | {b2bNameSite} | {localStorage.getItem("langb2b") === "fr" ? b2bDescriptionSite : "B2B marketplace and B2B networking platform in France and abroad"}</title>
                </MetaTags>
                <div className="page-content">
                    <section style={{ backgroundColor: '#fff', minHeight: '450px' }}>
                        <div className="container">
                            <div className="col-md-12 pb-5" style={{ backgroundColor: '#fff', marginBottom: '65px' }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="div-loginn">
                                            <div className="connxtion_header">
                                                <h2 className='mb-0'>VOUS ÊTES NOUVEAU SUR <br /> « {b2bNameSite}  » ?</h2>
                                            </div>
                                            <h6 className="title__bordered pt-2 pb-2" style={{ fontSize: "20px" }}>CRÉEZ VOTRE COMPTE EXPERT</h6>
                                            <div className="content-ihc">
                                                <div className="section-detail-wrapper px-3 mx-3" style={{ margin: 'auto' }}>
                                                    {this.state.showErrorMessage ? <div id="error">{this.state.errorMessage}</div> : <></>}
                                                    <form className="signup_form form_modal_style" id="page_signup_form" name="myForm" style={{ display: 'block' }} method="post">
                                                        <div className="form-field form-field2">
                                                            <i className="fa fa-user" />
                                                            <input type="email" className="email_user inputt" value={this.state.username} onChange={this.changeUsername} id="user_email" placeholder="Email (login)" required="required" style={{ width: '100%', paddingLeft: '60px', height: '36px', border: 'none' }} />
                                                        </div>
                                                        {/* password */}
                                                        <div className="form-field form-field2">
                                                            <i className="fa fa-lock" />
                                                            <input type={this.state.showPassword} className="password_user_signup inputt" id="reg_pass" onChange={this.changePassword} placeholder="Mot de passe" required="required" />
                                                            <div className="pass_eye" onClick={this.clickShowPassword}><i className="fa fa-eye" aria-hidden="true" /></div>
                                                        </div>
                                                        <div className="form-field form-field2">
                                                            <i className="fa fa-lock" />
                                                            <input type={this.state.showConfirmerPassword} className="repeat_password_user_signup inputt" id="re_password" onChange={this.changeConfirmerPassword} placeholder="Confirmer le mot de passe" required="required" />
                                                            <div className="pass_eye" onClick={this.clickShowConfirmerPassword}><i className="fa fa-eye" aria-hidden="true" /></div>
                                                        </div>
                                                        <div className="clearfix" />
                                                        <div className="form-field" style={{ position: 'relative' }}>
                                                            <input type="hidden" name="inscription" defaultValue={1} />
                                                            <i className="icone-btn-connecter" />
                                                            <button type="button" className="btn-submit btn-connecter" onClick={this.submitForm} style={{ width: '100%' }}>{this.state.textLogin}</button>
                                                        </div>
                                                        <div className="clearfix" />
                                                    </form>
                                                </div>
                                                <div className="div_text_ou">
                                                    <span />
                                                    <span className="text-ou">Ou</span>
                                                    <span />
                                                </div>
                                                <div className="section-detail-wrapper px-3 mx-3">
                                                    {/*---------------login google html ---------------------*/}
                                                    <div className="container-connxtion-google">
                                                        <div className="connxtion-google">
                                                            <div className="impu-form-line impu-form-submit">
                                                                <div className="div-btn-connecter">
                                                                    <i className="icone-btn-connecter icon-google" />
                                                                    <div className="btn-connecter btn-connecter-google"><span>SE CONNECTER AVEC GOOGLE</span></div>
                                                                    <GoogleLogin
                                                                        clientId={GoogleKey}
                                                                        buttonText="Connexion avec google"
                                                                        className="GoogleLoginStyle"
                                                                        onSuccess={this.responseGoogle}
                                                                        onFailure={this.responseGoogle}
                                                                        cookiePolicy={'single_host_origin'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*--------------------end login google html ----------------------*/}
                                                    {/* <div className="container-connxtion-google">
                                                <div className="connxtion-google">
                                                    <div className="impu-form-line impu-form-submit">
                                                        <div className="div-btn-connecter">
                                                            <i className="icone-btn-connecter icon-linkedin" />
                                                            <div className="btn-connecter btn-connecter-linkedin"><span>SE CONNECTER AVEC LINKEDIN </span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                                    <h6 style={{ textAlign: 'center' }}>Vous avez déjà un compte ? <br />
                                                        <Link to={"/" + i18next.language + "/signin"}>CONNECTEZ-VOUS</Link>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="div-loginn" style={{ padding: '0px', textAlign: 'center', border: 'none', minHeight: '0px', height: '300px' }}>
                                            <img src="/upload/gif-inscription/inscriptionEXPERT.gif" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}


