import './style.css';
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { b2bNameSite, b2bDescriptionSite } from '../../config.json';
import ReactGA from 'react-ga'
export default class Index extends Component {

    constructor(props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        if (document.getElementById("header_b2b")) {
            document.getElementById("header_b2b").style.opacity = "1";
            document.getElementById("header_b2b").style.display = "block";
        }
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>{localStorage.getItem("langb2b") === "fr" ? "Inscription" : "Registration"} | {b2bNameSite} | {localStorage.getItem("langb2b") === "fr" ? b2bDescriptionSite : "B2B marketplace and B2B networking platform in France and abroad"}</title>
                </MetaTags>
                <div className="page-content">
                    <section style={{ backgroundColor: '#fff', minHeight: '450px' }}>
                        <h1 className="title__bordered">Créez votre compte B2B</h1>
                        <div className="container" style={{ paddingTop: '60px', textAlign: 'center' }}>
                            <div className="row col-md-6 widget de_widget_categories prod-list" style={{ backgroundColor: '#fff', margin: 'auto' }}>
                                <div className="col-md-6" style={{}}>
                                    <Link className="a_tag" to={"/" + i18next.language + "/compte-entreprise/"}>
                                        <img src="/assets/img/entreprise.png" style={{ width: '150px' }} />
                                        <br />
                                        <span style={{ fontFamily: 'Calibri Light', color: '#2d2d2d', fontSize: '19px' }}>COMPTE</span>
                                        <br />
                                        <span style={{ fontFamily: 'Calibri', color: '#2d2d2d', fontSize: '22px', fontWeight: 700 }}> ENTREPRISE</span>
                                    </Link>
                                </div>
                                <div className="col-md-6">
                                    <Link className="a_tag" to={"/" + i18next.language + "/compte-expert/"}>
                                        <img src="/assets/img/expert.png" style={{ width: '150px' }} />
                                        <br />
                                        <span style={{ fontFamily: 'Calibri Light', color: '#2d2d2d', fontSize: '19px' }}>COMPTE</span>
                                        <br />
                                        <span style={{ fontFamily: 'Calibri', color: '#2d2d2d', fontSize: '22px', fontWeight: 700 }}> EXPERT&nbsp;</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}


