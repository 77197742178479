import React, { Component } from 'react';
import DataHome from '../../services/home';
import { Link, NavLink } from 'react-router-dom';
import i18next from 'i18next';
import './style.css';
import { withTranslation } from 'react-i18next';
class EntrepriseParSecteurs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [
                <ItemLoader />, <ItemLoader />, <ItemLoader />,
                <ItemLoader />, <ItemLoader />, <ItemLoader />,
                <ItemLoader />, <ItemLoader />, <ItemLoader />,
            ]
        }
        DataHome.getSecteurs().then((res) => {
            if (res.data.get) {
                var items = [];
                res.data.data.forEach(secteur => {
                    var count = 0;
                    secteur.subcategorys.forEach(sec => {
                        count += sec.count
                    })

                    if (count > 0) {
                        if (i18next.language == "fr" || i18next.language == "fr-FR") {
                            items.push(<Item id={secteur.term_id} name={secteur.name} cunt={count} subSecteurs={secteur.subcategorys} />);
                        } else if (i18next.language == "en") {
                            items.push(<Item id={secteur.term_id} name={secteur.en_name} cunt={count} subSecteurs={secteur.subcategorys} />);
                        } else {
                            items.push(<Item id={secteur.term_id} name={secteur.name} cunt={count} subSecteurs={secteur.subcategorys} />);
                        }

                    }
                });
                setTimeout(() => {
                    this.setState({ items: [] });
                    this.setState({ items: items });
                }, 1000);

            }
        });
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="section-full  content-inner-1">
                    <div className="container ">
                        <div className="section-head text text-center" style={{ "marginBottom": "0", "marginTop": "0px" }}>
                            <h2 className="text-uppercase"> {t("LISTE DES ENTREPRISES PAR SECTEURS D'ACTIVITÉS")}</h2>
                            <div className="dlab-separator-outer ">
                                <div className="dlab-separator bg-secondry style-skew"></div>
                            </div>
                        </div>
                        <div className=" row">
                            {this.state.items}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default withTranslation()(EntrepriseParSecteurs);


class Item extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: props.id,
            name: props.name,
            cunt: props.cunt,
            subSecteurs: props.subSecteurs,
            show: false,
            classshow: ""
        }
    }
    clickSecteur = (e) => {
        if (this.state.show) {
            this.setState({ show: false, classshow: "" });
        } else {
            this.setState({ show: true, classshow: "show" });
        }

    }
    render() {
        var itemSecteurs = [];
        if (this.state.subSecteurs)
            this.state.subSecteurs.forEach(secteur => {
                if (secteur.count > 0) {
                    if (i18next.language == "fr" || i18next.language == "fr-FR") {

                        itemSecteurs.push(<ItemSecteurs name={secteur.name} cunt={secteur.count} url={secteur.href} id={secteur.id} />);
                    } else if (i18next.language == "en") {
                        itemSecteurs.push(<ItemSecteurs name={secteur.en_name} cunt={secteur.count} url={secteur.href} id={secteur.id} />);
                    } else {
                        itemSecteurs.push(<ItemSecteurs name={secteur.name} cunt={secteur.count} url={secteur.href} id={secteur.id} />);
                    }
                }

            });

        return (
            <div className="col-md-4" style={{ "padding": "5px", "margin": "-4px -1px" }}>
                <div className="panel-group">
                    <div className="panel panel-default">
                        <div onClick={this.clickSecteur} className="panel-heading" style={{ "border": "none", "background": "#fff", "padding": "0 5px", "margin": "3px 0", "height": "45px" }}>
                            <h4 className="panel-title">
                                <a style={{ "fontSize": "12px", "fontWeight": "700" }} data-toggle="collapse" href="javascript:void(0)" className="collapsed" aria-expanded="false">
                                    <span className="span-insideli">{this.state.cunt}</span>{this.state.name}
                                </a>
                            </h4>
                        </div>

                        <div className={"modal-login-hadeer panel-collapse-collapse " + this.state.classshow} onClick={this.clickSecteur}>
                            <div><div className='w-100'>
                                <div className="div-login">
                                    <div className="connxtion_header">
                                        <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b">{this.state.name}</h2>
                                        <div className="divbtnclose" onClick={this.clickSecteur}>
                                            <span className="close closemodalheader">×</span>
                                        </div>
                                    </div>
                                    <div className="connxtion_body">
                                        <ul className="list-group list-group-ul">
                                            {itemSecteurs}
                                        </ul>
                                    </div>
                                </div>
                            </div></div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

class ItemSecteurs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: props.name,
            cunt: props.cunt,
            url: props.url,
            id: props.id,
        }
    }

    render() {

        return (
            <li className="list-group-item 9996669999996">
                <Link to={"/" + i18next.language + this.state.url}
                    state={{ id: this.state.id }}
                    style={{ color: "#000" }}>
                    <span className="span-insideli">{this.state.cunt}</span>{this.state.name}
                </Link>
            </li>
        );
    }
}

class ItemLoader extends Component {
    render() {

        return (
            <div className="col-md-4" style={{ "padding": "5px", "margin": "-4px -1px" }}>
                <div className="panel-group">
                    <div className="panel panel-default">
                        <div className="panel-heading" style={{ "border": "none", "background": "#fff", "padding": "5px", "margin": "3px 0", "height": "30px" }}>
                            <h4 className="panel-title">
                                <a style={{ "fontSize": "12px", "fontWeight": "700" }} data-toggle="collapse" href="javascript:void(0)" className="collapsed" aria-expanded="false">
                                    <span className="span-insideli loader-secteurs-cont"></span><span className="loader-secteurs-name"></span>
                                </a>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}